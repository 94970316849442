import { useEffect, useState } from "react";
import { Avatar, Button, List, Popconfirm, Skeleton, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { Trash } from "lucide-react";

import Cookies from "js-cookie";

import { UserService } from "../../services/apis/userService";

import styles from "./styleList.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface User {
    id: string;
    id_auth: string;
    created_at: string;
    cpf: string;
    name: string;
    email: string;
    phone_number: string;
    instituition_id_owner: string;
    instituition_owner: string;
    status: string;
    role: string;
    role_ref: string;
    permission_events: boolean;
}

interface Startup {
    id: number;
    created_at: string;
    cnpj: string;
    cei: string;
    nome_fantasia: string;
    razao_social: string;
    categoria: string;
    data_fundacao: string;
    numero_funcionarios: string;
    cnae: string;
    contabilidade: string;
    valor_pagamento: string;
    contatos: {
      email: string;
      telefone: string;
      celular: string;
      site: string;
    };
    endereco: {
      street: string;
      number: string;
      neighborhood: string;
      city: string;
      state: string;
      cep: string;
      complemento: string;
    };
    owners: {
      nome_responsavel: string;
      cargo: string;
    };
    porte: string;
    instituition_owner: string;
    instituition_cnpj_owner: string;
    id_initial_create_enterprise: string;
}

interface DataType extends User {
    gender?: string;
    picture: {
      large?: string;
      medium?: string;
      thumbnail?: string;
    };
    nat?: string;
    loading: boolean;
  }

  interface Filter {
    text: string;
    cascade: [keyof DataType, any][];
  }

  interface ListProps {
    setCurrentUser: any;
    setTypeModal: any;
    setVisible: any;
    dataUsers: DataType[];
    initLoading: boolean;
    count: number;
    onLoadMore: () => void;
    updateStatus: (id: string, status: string) => void;
    requestPasswordReset: (id: string) => void;
    setLoadingSwitch: any;
    setLoadingResetPassword: any;
    loadingSwitch: boolean;
    loadingResetPassword: boolean;
    setOpenModalDelete: any;
    openModalDelete: any;
  }

export function ListContainer({
  setCurrentUser,
  setTypeModal,
  setVisible,
  dataUsers,
  initLoading,
  count,
  onLoadMore,
  updateStatus,
  requestPasswordReset,
  setLoadingSwitch,
  setLoadingResetPassword,
  loadingSwitch,
  loadingResetPassword,
  setOpenModalDelete,
  openModalDelete,
}: ListProps) {
    const user = Cookies.get("user");
    const parseUser = user ? JSON.parse(user) : null;

    const styleBySubDomain = useSelector((state: RootState) => state.styleSlice);

    const loadMore = (
      <div
        style={{
          textAlign: "center",
          marginTop: 10,
          marginBottom: 10,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>Mostrar mais</Button>
      </div>
    );

    function hexToRGBA(hex: string, alpha = 1) {
      // Remove o # se estiver presente
      hex = hex.replace('#', '');
    
      // Se o código hexadecimal for de forma curta (ex: #FFF), converte para forma longa
      if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('');
      }
    
      // Extrai os valores RGB'
      const r = parseInt(hex.slice(0, 2), 16);
      const g = parseInt(hex.slice(2, 4), 16);
      const b = parseInt(hex.slice(4, 6), 16);
    
      // Retorna o formato RGBA
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

  return (
    <div>
      <List
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={count < dataUsers.length ? loadMore : null}
        dataSource={dataUsers.slice(0, count)}
        bordered={true}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <div 
                className={styles.listActions} 
                >
                <div>
                  <Switch
                    checkedChildren="Ativo"
                    unCheckedChildren="Inativo"
                    defaultChecked={item?.status === "active" ? true : false}
                    loading={loadingSwitch}
                    value={item?.status === "active" ? true : false}
                    onChange={(checked) =>
                      updateStatus(item.id, checked ? "active" : "inactive")
                    }
                  />
                </div>
                <div>
                  <Popconfirm
                    title="Resetar senha de acesso?"
                    description="O usuário receberá um e-mail com as instruções para resetar a senha."
                    okText="Confirmar"
                    cancelText="Cancelar"
                    placement="bottomRight"
                    onConfirm={() => {
                      requestPasswordReset(item.id_auth.toString());
                    }}
                  >
                    <Button 
                      type="primary" 
                      danger 
                      size="small"
                      loading={loadingResetPassword}
                    >
                      Resetar Senha
                    </Button>
                  </Popconfirm>
                </div>
                <a
                  key="list-loadmore-edit"
                  onClick={() =>{
                    setCurrentUser({
                      ...item,
                    })
                    setTypeModal({
                      type: "edit",
                      title: "Editar Usuário",
                      description: "Edite as informações do usuário",
                    })
                    setVisible(true);
                  }}
                >
                  Detalhes
                </a>
                <a
                  key="list-loadmore-more"
                  style={{
                    color: "red",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    setOpenModalDelete({
                      open: true,
                      name: item?.name ?? 'Carregando...',
                      id: item.id_auth,
                    })
                  }
                >
                  Delete
                  <Trash size={18} style={{ marginLeft: 5 }} />
                </a>
              </div>,
            ]}
            style={{ 
              backgroundColor: (item.instituition_id_owner === parseUser.id) ? 
              hexToRGBA(styleBySubDomain.backgroundColor, 0.05) : 
              '',
            }}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={<Avatar />}
                title={
                  <a
                    onClick={() =>
                      console.log("navigate to details", item.id)
                    }
                  >
                    {'#'+ (index + 1) + ' ' + item?.name}
                  </a>
                }
                description={`Instuição: ${item?.instituition_owner}`}
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
}