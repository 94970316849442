const siglasUfs = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
]

const estados = [
    { id: 1, sigla: 'AC', nome: 'Acre' },
    { id: 2, sigla: 'AL', nome: 'Alagoas' },
    { id: 3, sigla: 'AP', nome: 'Amapá' },
    { id: 4, sigla: 'AM', nome: 'Amazonas' },
    { id: 5, sigla: 'BA', nome: 'Bahia' },
    { id: 6, sigla: 'CE', nome: 'Ceará' },
    { id: 7, sigla: 'DF', nome: 'Distrito Federal' },
    { id: 8, sigla: 'ES', nome: 'Espírito Santo' },
    { id: 9, sigla: 'GO', nome: 'Goiás' },
    { id: 10, sigla: 'MA', nome: 'Maranhão' },
    { id: 11, sigla: 'MT', nome: 'Mato Grosso' },
    { id: 12, sigla: 'MS', nome: 'Mato Grosso do Sul' },
    { id: 13, sigla: 'MG', nome: 'Minas Gerais' },
    { id: 14, sigla: 'PA', nome: 'Pará' },
    { id: 15, sigla: 'PB', nome: 'Paraíba' },
    { id: 16, sigla: 'PR', nome: 'Paraná' },
    { id: 17, sigla: 'PE', nome: 'Pernambuco' },
    { id: 18, sigla: 'PI', nome: 'Piauí' },
    { id: 19, sigla: 'RJ', nome: 'Rio de Janeiro' },
    { id: 20, sigla: 'RN', nome: 'Rio Grande do Norte' },
    { id: 21, sigla: 'RS', nome: 'Rio Grande do Sul' },
    { id: 22, sigla: 'RO', nome: 'Rondônia' },
    { id: 23, sigla: 'RR', nome: 'Roraima' },
    { id: 24, sigla: 'SC', nome: 'Santa Catarina' },
    { id: 25, sigla: 'SP', nome: 'São Paulo' },
    { id: 26, sigla: 'SE', nome: 'Sergipe' },
    { id: 27, sigla: 'TO', nome: 'Tocantins' }
]

export { 
    siglasUfs,
    estados
 };