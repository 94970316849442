import { connectWebSocket } from './websocket';
import { addNotification } from '../../store/notificationSlice';

export const initializeWebSocket = (dispatch: (action: any) => void): void => {
  const handleMessage = (message: any, dispatch: (action: any) => void): void => {
    if (message.type === 'TICKET_UPDATE') {
      dispatch(addNotification(message));
    }
  };

  connectWebSocket('ws://localhost:8081', dispatch, handleMessage);
};