import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITicket {
    id_initial: string;
    title: string;
    description: string;
    date_ocurrency: string;
    category: string;
    priority: string;
}

const initialState: ITicket = {
    id_initial: '',
    title: '',
    description: '',
    date_ocurrency: '',
    category: '',
    priority: '',
};

export const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        setTitle: (state, action: PayloadAction<Partial<ITicket['title']>>) => {
            state.title = action.payload;
        },
        setDescription: (state, action: PayloadAction<Partial<ITicket['description']>>) => {
            state.description = action.payload;
        },
        setDateOcurrency: (state, action: PayloadAction<Partial<ITicket['date_ocurrency']>>) => {
            state.date_ocurrency = action.payload;
        },
        setCategory: (state, action: PayloadAction<Partial<ITicket['category']>>) => {
            state.category = action.payload;
        },
        setPriority: (state, action: PayloadAction<Partial<ITicket['priority']>>) => {
            state.priority = action.payload;
        },
        setIdInitial: (state, action: PayloadAction<Partial<ITicket['id_initial']>>) => {
            state.id_initial = action.payload;
        },

        resetForm: () => initialState,

    },
});

export const { 
    setTitle,
    setDescription,
    setDateOcurrency,
    setCategory,
    setPriority, 
    setIdInitial, 
    resetForm 
} = ticketsSlice.actions;
export default ticketsSlice.reducer;
