import { IEvent } from "../../types/eventTypes";
import { api } from "./configs";

export class EventsServices {
    getEventsByOwner = async () => {
        try {
            const response = await api.get(`/events/list`);
            return response.data.data;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    getAllEvents = async () => {
        try {
            const response = await api.get(`/events/list/all`);
            return response.data.data;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    getEventById = async (id: string) => {
        try {
            const response = await api.get(`/events/list/${id}`);
            return response.data.data[0];
        } catch (error: any) {
            throw new Error(error);
        }
    }

    createEvent = async (event: IEvent) => {
        try {
            const response = await api.post(`/events/register`, event);
            return response.data.data;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    updateEvent = async (idEvent: number, event: IEvent) => {
        try {
            const response = await api.put(`/events/update/${idEvent}`, event);
            return response.data.data;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    deleteEvent = async (idEvent: number) => {
        try {
            const response = await api.delete(`/events/delete/${idEvent}`);
            return response.data.data;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    // Public Events
    getAllPublicEvents = async () => {
        try {
            const response = await api.get(`/events-public/list/all`);
            return response.data.data;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    invitationEvent = async (email: string) => {
        try {
            const response = await api.post(`/events-public/public-invite`, { email });
            return response.data.data;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    inviteEditEvent = async (email: string, id_auth: string) => {
        try {
            const response = await api.post(`/events/edit-invite`, { 
                email, 
                id_auth
            });
            return response.data.data;
        } catch (error: any) {
            throw new Error(error);
        }
    }
}