import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  idInitial: ''
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIdInitial(state, action) {
      state.idInitial = action.payload;
    },
    resetIdInitial(state) {
      state.idInitial = '';
    },
    generateIdInitial(state) {
      state.idInitial = uuidv4();
    }
  }
});

export const { setIdInitial, resetIdInitial, generateIdInitial } = commonSlice.actions;

export default commonSlice.reducer;