import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Spin,
} from "antd";

import { RootState } from "../../../store/store";
import {
  setInnovationCenterData,
  setAddress,
  // setContacts,
  // setPresident
} from "../../../store/updateInnovationCenterSlice";
import {
  clearStringOnlyNumbers,
  inputMaskCEP,
  inputMaskCPFCNPJ,
  inputMaskTELWithDDD,
} from "../../../libs/masks";
import {
  validaEmail,
  validarCNPJ,
  validateCEP,
  validateBrazilianPhoneNumber,
} from "../../../services/validation.service";
import { InnovationCenterService } from "../../../services/apis/innovationCenterService";
import { AddressService } from "../../../services/apis/addressService";

import styles from "./innovationCenter.module.scss";
import { NotificationType } from "../../../types/commonTypes";
import { IInnovationCenterTypes } from "../../../types/innovationCenterTypes";
import InnovationCenterFormatter from "./innovationCenterFormatter";

const { Option } = Select;
const { TextArea } = Input;

interface IAddresProps {
  id: number;
  sigla: string;
  nome: string;
  regiao?: {
    id: number;
    sigla: string;
    nome: string;
  };
}

export function InnovationCenter() {
  const [dataDetails, setDataDetails] = useState<IInnovationCenterTypes>();
  const [ufs, setUfs] = useState<IAddresProps[]>([]);

  const currentWindowWidth = window.innerWidth;
  const dispatch = useDispatch();
  const currentProfile = Cookies.get("profile");
  const parseProfile = currentProfile ? JSON.parse(currentProfile) : null;
  const { cnpj } = parseProfile[0];
  const [form] = Form.useForm();
  const formatted = new InnovationCenterFormatter();
  const [api, contextHolder] = notification.useNotification();
  const innovationCenterService = new InnovationCenterService();
  const addressService = new AddressService();

  const startUpBasicData = useSelector(
    (state: RootState) => state.updateInnovationCenterSlice.innovationCenterData
  );
  const innovationCenterAddressData = useSelector(
    (state: RootState) => state.updateInnovationCenterSlice.address
  );

  const [loading, setLoading] = React.useState(false);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const getAllUfs = async () => {
    try {
      const response = await addressService.getUfs();
      setUfs(response);
    } catch (error) {
      return;
    }
  };

  const getInnovationCenter = async () => {
    try {
      if (!cnpj) {
        throw new Error('Nenhuma instituição encontrada!')
      }
      const readInnovationCenter = await innovationCenterService.readInnovationCenterByCnpj(cnpj?.toString())
      form.setFieldsValue({
        cnpj: inputMaskCPFCNPJ(readInnovationCenter.cnpj),
        name_innovation_center: readInnovationCenter.name_innovation_center,
        description: readInnovationCenter.description,

        address_cep: inputMaskCEP(readInnovationCenter.address.cep),
        address_street: readInnovationCenter.address.street,
        address_number: readInnovationCenter.address.number,
        address_complemento: readInnovationCenter.address.complemento,
        address_neighborhood: readInnovationCenter.address.neighborhood,
        address_city: readInnovationCenter.address.city,
        address_state: readInnovationCenter.address.state,

        contact_email: readInnovationCenter.contacts.email,
        contact_phone: inputMaskTELWithDDD(readInnovationCenter.contacts.phone),

        president_name: readInnovationCenter.president.name,
        president_email: readInnovationCenter.president.email,
        president_phone: inputMaskTELWithDDD(readInnovationCenter.president.phone),
        president_biginning_of_term: readInnovationCenter.president.biginning_of_term,
        president_end_of_term: readInnovationCenter.president.end_of_term,
      })
      setDataDetails(readInnovationCenter);
    } catch (error: any) {
      openNotificationWithIcon(
        'error',
        'Erro',
        error.message ?? 'Não foi possivel processar sua solicitação!'
      )
    }
  }

  const handleChangeAddress = (
    event: React.ChangeEvent<HTMLInputElement>,
    target: { name: string }
  ) => {
    const { value } = event.target;
    const { name } = target;
    const key = name;
    if (key === "address_cep") {
      if (value.length === 8) {
        setLoading(true);
        handleAddressByCep(clearStringOnlyNumbers(value));
        form.setFieldsValue({
          address_cep: inputMaskCEP(value),
        });
      }

      dispatch(setAddress({ [name]: clearStringOnlyNumbers(value) }));
      return;
    }

    dispatch(setAddress({ [name]: value }));
  };

  const handleAddressByCep = async (cep: string) => {
    try {
      const response = await addressService.getAddressByCep(cep);
      dispatch(
        setAddress({
          cep: response.cep,
          street: response.logradouro,
          neighborhood: response.bairro,
          city: response.localidade,
          state:
            ufs?.find((option) => option.sigla === response.uf)?.nome ??
            "state not found",
        })
      );

      form.setFieldsValue({
        address_street: response.logradouro,
        address_neighborhood: response.bairro,
        address_city: response.localidade,
        address_state: ufs?.find((option) => option.sigla === response.uf)?.nome ?? "state not found",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        setAddress({
          street: "",
          number: "",
          neighborhood: "",
          city: "",
          state: "",
          cep: "",
          complemento: "",
        })
      );
      return;
    }
  };

  const handleChangeFilterState = (value: string) => {
    dispatch(setAddress({ state: value }));
  };

  const handleFinish = async (values: any) => {
    setLoading(true);
    try {
      const formattedData = formatted.innovationCenterUpdated({
        ...values,
      });
      if (!dataDetails?.id) {
        openNotificationWithIcon(
          'error',
          'Erro',
          'Erro ao atualizar empresa [id]'
        )
        return;
      }
      await innovationCenterService.upDateInnovationCenter(dataDetails.cnpj, formattedData);
      setLoading(false);
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Dados da empresa atualizados com sucesso!"
      );
    } catch (error: any) {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Erro",
        error['message'] ?? "Erro ao cadastrar empresa"
      );
      return;
    }
  };

  useEffect(() => {
    getInnovationCenter();
    getAllUfs();
    dispatch(setInnovationCenterData({}));
  }, []);

  useEffect(() => {
  }, [dataDetails]);

  return (
    <Form layout="vertical" form={form} onFinish={handleFinish}>
      {contextHolder}
      <Spin
        tip="Loading"
        size="large"
        className={styles.spinLoading}
        spinning={loading}
      >
        <div className={styles.formContainer}>
          <section className={styles.sectionBasicData}>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Dados</div>
              <div className={styles.inputGroup}>
                <Form.Item
                  label="Centro de Inovação"
                  name="name_innovation_center"
                  rules={[
                    {
                      required: true,
                      message: "Campo Centro de Inovação é obrigatório",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item
                  label="CNPJ"
                  name="cnpj"
                  rules={[
                    {
                      required: true,
                      message: "Campo CNPJ é obrigatório",
                      validator: (rule, value) => {
                        if (validarCNPJ(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject((rule.message = "CNPJ inválido"));
                      },
                    },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        cnpj: inputMaskCPFCNPJ(e.target.value),
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item
                  label="Descrição"
                  name="description"
                >
                  <TextArea rows={4} />
                </Form.Item>
              </div>
            </div>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Endereço</div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="CEP"
                  name="address_cep"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    {
                      required: true,
                      message: "Campo CEP é obrigatório",
                      validator: (rule, value) => {
                        if (validateCEP(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject((rule.message = "CEP inválido"));
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "25%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "address_cep" });
                    }}
                    value={inputMaskCEP(innovationCenterAddressData.cep)}
                  />
                </Form.Item>
                <Form.Item
                  label="Logradouro"
                  name="address_street"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    {
                      required: true,
                      message: "Campo Logradouro é obrigatório",
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "57%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "street" });
                    }}
                    value={innovationCenterAddressData.street}
                  />
                </Form.Item>
                <Form.Item
                  label="Número"
                  name="address_number"
                  rules={[
                    { required: true, message: "Campo Número é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "18%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "number" });
                    }}
                    value={innovationCenterAddressData.number}
                  />
                </Form.Item>
              </div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="Bairro"
                  name="address_neighborhood"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    { required: true, message: "Campo Bairro é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "33%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "neighborhood" });
                    }}
                    value={innovationCenterAddressData.neighborhood}
                  />
                </Form.Item>
                <Form.Item
                  label="Cidade"
                  name="address_city"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    { required: true, message: "Campo Cidade é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "46%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "city" });
                    }}
                    value={innovationCenterAddressData.city}
                  />
                </Form.Item>
                <Form.Item
                  label="UF"
                  name="address_state"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    { required: true, message: "Campo UF é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "20%" }
                      : { width: "100%" }
                  }
                >
                  <Select
                    onChange={handleChangeFilterState}
                    value={innovationCenterAddressData.state}
                    placeholder="Selecione"
                  >
                    {ufs?.map((option) => (
                      <Option key={option.sigla} value={option.nome}>
                        {option.sigla}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item label="Complemento" name="address_complemento">
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "complemento" });
                    }}
                    value={innovationCenterAddressData.complemento}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Contato</div>
              <div className={styles.inputGroup}>
                <Form.Item
                  label="E-mail"
                  name="contact_email"
                  rules={[
                    {
                      required: true,
                      message: "Campo E-mail é obrigatório",
                      validator: (rule, value) => {
                        if (validaEmail(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message = "E-mail inválido")
                        );
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Telefone"
                  name="contact_phone"
                  rules={[
                    {
                      required: true,
                      message: "Campo Celular é obrigatório",
                      validator: (rule, value) => {
                        if (
                          validateBrazilianPhoneNumber(
                            clearStringOnlyNumbers(value)
                          )
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message = "Celular inválido")
                        );
                      },
                    },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        contact_phone: inputMaskTELWithDDD(e.target.value),
                      })
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Presidente</div>
              <div className={styles.inputGroup}>
                <Form.Item
                  label="Nome do Presidente"
                  name="president_name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="E-mail"
                  name="president_email"
                  rules={[
                    {
                      required: true,
                      message: "Campo E-mail é obrigatório",
                      validator: (rule, value) => {
                        if (validaEmail(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message = "E-mail inválido")
                        );
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "50%" }
                      : { width: "100%" }
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Telefone"
                  name="president_phone"
                  rules={[
                    {
                      required: true,
                      message: "Campo Celular é obrigatório",
                      validator: (rule, value) => {
                        if (
                          validateBrazilianPhoneNumber(
                            clearStringOnlyNumbers(value)
                          )
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message = "Celular inválido")
                        );
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "49%" }
                      : { marginTop: "10px" }
                  }
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        president_phone: inputMaskTELWithDDD(e.target.value),
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="Presidente desde"
                  name="president_biginning_of_term"
                  rules={[{ required: false }]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "50%" }
                      : { width: "100%" }
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Presidente até"
                  name="president_end_of_term"
                  rules={[{ required: false }]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "50%" }
                      : { width: "100%" }
                  }
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </section>
        </div>
        <div className={styles.boxButtom}>
          <Form.Item className={styles.formButton}>
            <Button type="primary" htmlType="submit">
              Atualizar
            </Button>
          </Form.Item>
        </div>
        <div className={styles.contentSpin} />
      </Spin>
    </Form>
  );
}
