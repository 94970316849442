import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationState {
  updates: Array<any>;
}

const initialState: NotificationState = {
  updates: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<any>) => {
      state.updates.push(action.payload);
    },
  },
});

export const { addNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
