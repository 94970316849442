import { useEffect } from 'react';
import { Card, Row, Col, Statistic, List, Table, Progress, Button } from 'antd';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

export function Home() {
    const navigate = useNavigate();
    const validRoles = ['ATENDIMENTO'];
    const profile = Cookies.get("profile");
    const profileParsed = profile ? JSON.parse(profile) : null;

    useEffect(() => {
        if (validRoles.includes(profileParsed[0].role)) {
            navigate('/tikets');
        }
    }, []);
    const dataSource = [
        { key: '1', name: 'Chamado 1', status: 'Aberto' },
        // { key: '2', name: 'Chamado 2', status: 'Fechado' },
        // Adicione mais dados conforme necessário
      ];
    
      const columns = [
        { title: 'Nome', dataIndex: 'name', key: 'name' },
        // { title: 'Status', dataIndex: 'status', key: 'status' },
      ];

    return (
        <>
            <Row 
                gutter={16}
                style={{ marginTop: 20 }}
            >
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset:2 }}>
              <Card>
                <Statistic title="Chamados Abertos" value={0} />
              </Card>
            </Col>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset:2 }}>
              <Card>
                <Statistic title="Eventos Planejados" value={0} />
              </Card>
            </Col>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 2 }}>
              <Card>
                <Statistic title="Salas Reservadas" value={0} />
              </Card>
            </Col>
          </Row>
        
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }}>
              <Card title="Últimas Atividades">
                <List
                  dataSource={dataSource}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta title={item.name} description={`Status: ${item.status}`} />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }}>
              <Card title="Chamados Recentes">
                <Table dataSource={dataSource} columns={columns} />
              </Card>
            </Col>
          </Row>
              
          <Row gutter={24} style={{ marginTop: 20 }}>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 22, offset: 2 }}>
              <Card title="Resumo Financeiro">
                <Progress percent={0} status="active" />
                <Button type="primary" style={{ marginTop: 20 }}>Ver Detalhes</Button>
              </Card>
            </Col>
          </Row>
        </>
    );
}