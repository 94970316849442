import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/store";
import { setAddressData } from "../../../store/formDataSlice";
import { setCurrentStep } from "../../../store/currentStepSlice";
import { ButtonCustom } from "../../customButtom/buttomCustom";
import { InputText } from "../../inputText/inputText";
import { InputTextFilter } from "../../inputTextFilter/inputTextFilter";
import { AddressService } from "../../../services/apis/addressService";
import { clearStringOnlyNumbers, inputMaskCEP } from "../../../libs/masks";

import styles from "./addressDataStyles.module.scss";


interface AddressDataFormProps {
    optionsUf: {
        id: number,
        sigla: string,
        nome: string,
        regiao?: {
            id: number,
            sigla: string,
            nome: string
        }
    }[]
}
 
interface citiesProps {
    id: number,
    nome: string,
    microrregiao: {
        id: number,
        nome: string,
        mesorregiao: {
            id: number,
            nome: string,
            UF: {
                id: number,
                sigla: string,
                nome: string,
                regiao: {
                    id: number,
                    sigla: string,
                    nome: string
                }
            }
        }
    },
    "regiao-imediata": {
        id: number,
        nome: string,
        "regiao-intermediaria": {
            id: number,
            nome: string,
            UF: {
                id: number,
                sigla: string,
                nome: string,
                regiao: {
                    id: number,
                    sigla: string,
                    nome: string
                }
            }
        }
    }
}


export function AddressDataForm({ optionsUf }: AddressDataFormProps) {
    const dispatch = useDispatch(); 
    const innovationCenter = useSelector((state: RootState) => state.formData.addressData);
    const currentStep = useSelector((state: RootState) => state.currentStep.currentStep);
    const [cities, setCities] = useState<citiesProps[]>([]);
    const [loading, setLoading] = useState(false);
    const [errorCep, setErrorCep] = useState({
        error: false,
        message: ""
    });
    const [errorStreet, setErrorStreet] = useState({
        error: false,
        message: ""
    });
    const [errorNumber, setErrorNumber] = useState({
        error: false,
        message: ""
    });
    const [errorDistrict, setErrorDistrict] = useState({
        error: false,
        message: ""
    });
    const [errorCity, setErrorCity] = useState({
        error: false,
        message: ""
    });
    const [errorState, setErrorState] = useState({
        error: false,
        message: ""
    });

    const addressService = new AddressService();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === 'zip_code') {
            if (value.length >= 8) {
                setLoading(true);
                handleAddressByCep(clearStringOnlyNumbers(value));
            }

            dispatch(setAddressData({ [name]: clearStringOnlyNumbers(value) }));
        }

        dispatch(setAddressData({ [name]: value }));

    }

    const handleChangeFilterCity = (value: string) => {
        dispatch(setAddressData({ city: value }));
    }

    const handleChangeFilterState = (value: string) => {
        dispatch(setAddressData({ state: value }));
    }

    const handleSelectedUf = async (value: string) => {
        const codeUf = optionsUf.find(option => option.nome === value)?.id;

        if (!codeUf) {
            return;
        }

        try {
            const cities = await addressService.getCitiesByUf(codeUf);

            setCities(cities);
        } catch (error) {
            return;
        }
    }

    const handleAddressByCep = async (cep: string) => {
        try {
            const response = await addressService.getAddressByCep(cep);
            dispatch(setAddressData({
                street: response.logradouro,
                district: response.bairro,
                city: response.localidade,
                state: optionsUf.find(option => option.sigla === response.uf)?.nome ?? 'state not found',
                country: 'Brasil'
            }));

            setLoading(false);
        } catch (error) {
            return;
        }
    }

    const handleNext = () => {
        if (innovationCenter.zip_code === "") {
            setErrorCep({
                error: true,
                message: "CEP é obrigatório"        
            });
            return;
        }

        if (innovationCenter.street === "") {
            setErrorStreet({
                error: true,
                message: "Rua é obrigatório"
            });
            return;
        }

        if (innovationCenter.number === "") {
            setErrorNumber({
                error: true,
                message: "Número é obrigatório"
            });
            return;
        }

        if (innovationCenter.district === "") {
            setErrorDistrict({
                error: true,
                message: "Bairro é obrigatório"
            });
            return;
        }

        if (innovationCenter.city === "") {
            setErrorCity({
                error: true,
                message: "Cidade é obrigatório"
            });
            return;
        }

        if (innovationCenter.state === "") {
            setErrorState({
                error: true,
                message: "Estado é obrigatório"
            });
            return;
        }
        
        setErrorCep({
            error: false,
            message: ""
        });
        setErrorStreet({
            error: false,
            message: ""
        });
        setErrorNumber({
            error: false,
            message: ""
        });
        setErrorDistrict({
            error: false,
            message: ""
        });
        setErrorCity({
            error: false,
            message: ""
        });
        setErrorState({
            error: false,
            message: ""
        });
        
        dispatch(setCurrentStep(currentStep + 1));
    }

    const handleBack = () => {
        dispatch(setCurrentStep(currentStep - 1));
    }

    return (
        <>
            <div className={styles.containerBasicData}>
                <div className={styles.grupo1}>
                    <InputText 
                        label="CEP"
                        name="zip_code"
                        type="text"
                        placeholder="Digite o CEP"
                        value={inputMaskCEP(innovationCenter.zip_code)}
                        onChange={handleChange}
                        error={errorCep.error}
                        errorMensage={errorCep.message}
                        required
                    />
                    <InputText 
                        label="Rua"
                        name="street"
                        type="text"
                        placeholder="Digite o nome da rua"
                        value={innovationCenter.street}
                        onChange={handleChange}
                        loading={loading}
                        error={errorStreet.error}
                        errorMensage={errorStreet.message}
                        required
                    />
                    <InputText 
                        label="Número"
                        name="number"
                        type="text"
                        placeholder="Digite o número"
                        value={innovationCenter.number}
                        onChange={handleChange}
                        error={errorNumber.error}
                        errorMensage={errorNumber.message}
                        required
                    />
                </div>
                <div className={styles.grupo2}>
                    <InputText 
                        label="Complemento"
                        name="complement"
                        type="text"
                        placeholder="Digite o complemento"
                        value={innovationCenter.complement}
                        onChange={handleChange}
                    />
                    <InputText 
                        label="Bairro"
                        name="district"
                        type="text"
                        placeholder="Digite o bairro"
                        value={innovationCenter.district}
                        onChange={handleChange}
                        loading={loading}
                        error={errorDistrict.error}
                        errorMensage={errorDistrict.message}
                        required
                    />
                </div>
                <div className={styles.grupo3}>
                    <InputTextFilter 
                        label="Cidade"
                        name="city"
                        type="text"
                        placeholder="Digite a cidade"
                        value={innovationCenter.city}
                        onChange={handleChangeFilterCity}
                        options={cities?.map(city => ({ value: city.nome }))}
                        loading={loading}
                        error={errorCity.error}
                        errorMensage={errorCity.message}
                        required
                    />
                    <InputTextFilter 
                        label="Estado"
                        name="state"
                        type="text"
                        placeholder="Digite o estado"
                        value={innovationCenter.state}
                        onChange={handleChangeFilterState}
                        selected={handleSelectedUf}
                        options={optionsUf?.map(option => ({ value: option.nome, sigla: option.sigla}))}
                        loading={loading}
                        error={errorState.error}
                        errorMensage={errorState.message} 
                        required                   
                    />
                    <InputText
                        label="País"
                        name="country"
                        type="text"
                        placeholder="Digite o país"
                        value={innovationCenter.country}
                        onChange={handleChange}
                        loading={loading}
                    />
                </div>
            </div>
            <div className={styles.contentButtons}>
                <ButtonCustom 
                  label="Voltar"
                  onClick={handleBack}
                  style={{backgroundColor: "var(--gray-100)", color: "#000"}}
                />
                <ButtonCustom 
                  label="Próximo" 
                  onClick={handleNext}
                />
            </div>
        </>
    );
}