import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderFilters } from "../../components/header_filters/headerFilters";
import {
  Avatar,
  Button,
  List,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Result,
  Skeleton,
  Spin,
  Switch,
} from "antd";
import { RegistrationService } from "../../services/apis/registrationSevice";
import Cookies from "js-cookie";
import { Trash } from "lucide-react";
// import { Details } from "./details";
import { AddressService } from "../../services/apis/addressService";

import styles from "./startUpStyles.module.scss";
import { StartUpService } from "../../services/apis/startUpService";
import { NotificationType } from "../../types/commonTypes";

type FilterCriteria = [keyof IStartup, any][];
interface IStartup {
  id: number;
  cnpj: string;
  cei: string;
  nome_fantasia: string;
  razao_social: string;
  categoria: string;
  data_fundacao: string;
  porte: string;
  numero_funcionarios: number;
  cnae: string;
  contabilidade: string;
  valor_pagamento: number;
  instituition_owner: string;
  instituition_cnpj_owner: string;
  status: string;
  owners: {
    nome_responsavel: string;
    cargo: string;
  };
  endereco: {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
    complemento: string;
  };
  contatos: {
    email: string;
    telefone: string;
    celular: string;
    site: string;
  };
}
interface DataType extends IStartup {
  gender?: string;
  name: {
    title?: string;
    first?: string;
    last?: string;
  };
  email?: string;
  picture: {
    large?: string;
    medium?: string;
    thumbnail?: string;
  };
  nat?: string;
  loading: boolean;
}

interface OpenDetailsState {
  open: boolean;
  data: DataType | null;
}

interface Filter {
  text: string;
  cascade: [keyof DataType, any][];
}

export function StartUpsPage() {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const [errorRequest, setErrorRequest] = useState({
    status: false,
    message: "",
  });
  const [openModalDelete, setOpenModalDelete] = useState({
    open: false,
    name: "",
    id: 0,
  });
  const [count, setCount] = useState(3);
  const [list, setList] = useState<DataType[]>([]);
  const [filteredData, setFilteredData] = useState<DataType[]>([]);
  const [filters, setFilters] = useState<Filter>({ text: "", cascade: [] });
  const [openDetails, setOpenDetails] = useState<OpenDetailsState>({
    open: false,
    data: null,
  });
  const [ufs, setUfs] = useState([]);

  const registerService = new RegistrationService();
  const addressService = new AddressService();
  const startUpService = new StartUpService();

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const startupCascadeFilters = [
    {
      value: "status",
      label: "Status",
      children: [
        {
          value: "active",
          label: "Ativo",
        },
        {
          value: "inactive",
          label: "Inativo",
        },
      ],
    },
    {
      value: "porte",
      label: "Porte",
      children: [
        {
          value: "Microempresa",
          label: "Microempresa",
        },
        {
          value: "Pequeno Porte",
          label: "Pequeno",
        },
        {
          value: "Médio Porte",
          label: "Médio",
        },
        {
          value: "Grande Porte",
          label: "Grande",
        },
      ],
    },
  ];

  const getAllUfs = async () => {
    try {
      const response = await addressService.getUfs();
      setUfs(response);
    } catch (error) {
      return;
    }
  };

  const rediectRegister = () => {
    navigate("/registrations/new-enterprise");
  };

  const updateStatus = async (id: number, status: string) => {
    setLoadingSwitch(true);
    try {
      await registerService.upDateStartup(id, { status: status });
      fetchData();
      setLoadingSwitch(false);
    } catch (error: any) {
      setLoadingSwitch(false);
      setErrorRequest({
        status: true,
        message: error.message,
      });
    }
  };

  const fetchData = async () => {
    setInitLoading(true);
    const dataUser = Cookies.get("profile");
    const dataParse = JSON.parse(dataUser ?? "{}");
    const { institution_cnpj_owner } = dataParse[0];
    try {
      const response = await registerService.readStartup(
        institution_cnpj_owner
      );
      setList(response);
      setFilteredData(applyFilters(response, filters));
      setInitLoading(false);
    } catch (error) {
      setInitLoading(false);
      return;
    }
  };

  //filtro unificado

  function removeAccents(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const applyFilters = (data: DataType[], { text, cascade }: Filter) => {
    // Convertendo o array de arrays para um objeto onde as chaves são os campos e os valores são arrays dos valores selecionados
    const cascadeObject = cascade.reduce((acc, [key, value]) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(value);
      return acc;
    }, {} as Record<string, any[]>);

    return data.filter((item) => {
      const textMatch =
        removeAccents(item.nome_fantasia.toLowerCase()).includes(
          text.toLowerCase()
        ) ||
        removeAccents(item.razao_social.toLowerCase()).includes(
          text.toLowerCase()
        ) ||
        removeAccents(item.categoria.toLowerCase()).includes(
          text.toLowerCase()
        );
      const cascadeMatch = Object.entries(cascadeObject).every(
        ([key, values]) => {
          if (key in item) {
            if (!values[0]) return true; // Se o array de valores está vazio, consideramos um match
            // Checa se o valor do item está incluído nos valores selecionados para esse filtro
            return values.includes(item[key as keyof DataType]);
          }
          return false; // Se a chave não existe, não consideramos um match
        }
      );
      return textMatch && cascadeMatch;
    });
  };

  const handleTextFilterChange = (value: string) => {
    setFilters((prev) => ({ ...prev, text: value }));
  };

  const handleCascadeFilterChange = (value: [keyof DataType, any][]) => {
    setFilters((prev) => ({ ...prev, cascade: value }));
  };

  useEffect(() => {
    setFilteredData(applyFilters(list, filters));
  }, [filters, list]);

  useEffect(() => {
    fetchData();
  }, []);

  const onLoadMore = () => {
    setCount(count + 3);
  };

  const loadMore = (
    <div
      style={{
        textAlign: "center",
        marginTop: 10,
        marginBottom: 10,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button onClick={onLoadMore}>Mostrar mais</Button>
    </div>
  );

  const confirmDelete = async (id: number) => {
    setLoading(true);
    try {
      await registerService.deleteStartup(id.toString());
      setLoading(false);
      setOpenModalDelete({
        open: false,
        name: "",
        id: 0,
      });
      fetchData();
    } catch (error: any) {
      setLoading(false);
      setErrorRequest({
        status: true,
        message: error.message,
      });
    }
  };

  const cancelDelete = () => {
    setErrorRequest({
      status: false,
      message: "",
    });
    setOpenModalDelete({
      open: false,
      name: "",
      id: 0,
    });
  };

  const requestPasswordReset = async (id: string) => {
    setLoadingResetPassword(true);
    try {
      await startUpService.requestPasswordReset(id);
      setLoadingResetPassword(false);
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Solicitação para redefinir senha enviada"
      );
    } catch (error: any) {
      setLoadingResetPassword(false);
      openNotificationWithIcon(
        "error",
        "Erro ao solicitar reset de senha",
        error.message ?? "Não foi possível solicitar reset de senha"
      );
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getAllUfs();
    }, 1000);
  }, []);
  return (
    <div>
      {contextHolder}
      <HeaderFilters
        changeButton={rediectRegister}
        text="nova empresa"
        changeSearch={handleTextFilterChange}
        changeCascader={handleCascadeFilterChange}
        disabledSearch={false}
        disabledCascade={false}
        optionsCascader={startupCascadeFilters}
      />
      <List
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={count < list.length ? loadMore : null}
        dataSource={filteredData.slice(0, count)}
        bordered={true}
        renderItem={(item) => (
          <List.Item
            actions={[
              <div className={styles.listActions}>
                <div>
                  <Switch
                    checkedChildren="Ativo"
                    unCheckedChildren="Inativo"
                    defaultChecked={item?.status === "active" ? true : false}
                    loading={loadingSwitch}
                    value={item?.status === "active" ? true : false}
                    onChange={(checked) =>
                      updateStatus(item.id, checked ? "active" : "inactive")
                    }
                  />
                </div>
                <div>
                  <Popconfirm
                    title="Resetar senha de acesso?"
                    description="O usuário receberá um e-mail com as instruções para resetar a senha."
                    okText="Confirmar"
                    cancelText="Cancelar"
                    placement="bottomRight"
                    onConfirm={() => {
                      requestPasswordReset(item.id.toString());
                    }}
                  >
                    <Button 
                      type="primary" 
                      danger 
                      size="small"
                      loading={loadingResetPassword}
                    >
                      Resetar Senha
                    </Button>
                  </Popconfirm>
                </div>
                <a
                  key="list-loadmore-edit"
                  onClick={() =>
                    navigate(`/start-up/details/${item.id}`)
                  }
                >
                  Detalhes
                </a>
                <a
                  key="list-loadmore-more"
                  style={{
                    color: "red",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    setOpenModalDelete({
                      open: true,
                      name: item?.nome_fantasia ?? item.razao_social,
                      id: item.id,
                    })
                  }
                >
                  Delete
                  <Trash size={18} style={{ marginLeft: 5 }} />
                </a>
              </div>,
            ]}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={<Avatar />}
                title={
                  <a
                    onClick={() =>
                      setOpenDetails({
                        open: true,
                        data: item,
                      })
                    }
                  >
                    {item?.nome_fantasia.length === 0
                      ? item?.razao_social
                      : item?.nome_fantasia}
                  </a>
                }
                description={item?.categoria}
              />
            </Skeleton>
          </List.Item>
        )}
      />
      <Modal
        title={`Deletar ${openModalDelete.name}?`}
        open={openModalDelete.open}
        onOk={() => confirmDelete(openModalDelete.id)}
        onCancel={cancelDelete}
        okText="Sim"
        cancelText="Cancelar"
      >
        {!loading && !errorRequest.status && (
          <Result
            status="error"
            title="Deseja realmente deletar essa startup?"
            subTitle="Essa ação não poderá ser desfeita."
          />
        )}
        <Spin
          tip="Loading"
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          spinning={loading}
        >
          <div />
        </Spin>
        {errorRequest.status && !loading && (
          <Result
            status="warning"
            title="Erro ao deletar startup"
            subTitle={errorRequest.message}
          />
        )}
      </Modal>
    </div>
  );
}
