import Cookies from "js-cookie";
import { clearStringOnlyNumbers, removeMoneyMask } from "../../../../libs/masks";

class RegistrationFormatter {
    startUpCreate(payload: any) {
        const getUser = Cookies.get('profile');
        const userParse = JSON.parse(getUser ?? '{}');
        const { institution_cnpj_owner, institution_owner } = userParse[0];

       return {
            id_initial_create_enterprise: payload.id_initial_create_enterprise,
            cnpj: clearStringOnlyNumbers(payload.cnpj),
            cnpj_instituition: institution_cnpj_owner,
            name_innovation_center: institution_owner,
            cei: payload.cei,
            role: "startup",
            role_ref: "innovation",
            nome_fantasia: payload.nome_fantasia,
            razao_social: payload.razao_social,
            categoria: payload.categoria,
            data_fundacao: payload.data_fundacao,
            porte: payload.porte,
            numero_funcionarios: payload.numero_funcionarios,
            cnae: payload.cnae,
            contabilidade: payload.contabilidade,
            valor_pagamento: removeMoneyMask(payload.valor_pagamento),
            nome_responsavel: payload.nome_responsavel,
            cargo_responsavel: payload.cargo_responsavel,
            address: {
                street: payload.street,
                number: payload.number,
                neighborhood: payload.neighborhood,
                city: payload.city,
                state: payload.state,
                cep: clearStringOnlyNumbers(payload.cep),
                complemento: payload.complemento
            },
            contacts: {
                email: payload.email,
                phone: clearStringOnlyNumbers(payload.phone),
                cellphone: clearStringOnlyNumbers(payload.cellphone),
                site: payload.site
            }
        }
    }
}

export default RegistrationFormatter;