import React from "react";
import { Descriptions } from "antd";
import type { DescriptionsProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../store/store";
import { ButtonCustom } from "../../../customButtom/buttomCustom";
import { RegistrationService } from "../../../../services/apis/registrationSevice";
import {
  setFeatureScreenConfirmation,
  setMessageScreenConfirmation,
  setScreenConfirmation,
  setSubMessageScreenConfirmation,
} from "../../../../store/screenConfirmationSlice";
import { ScreensConfirmation } from "../../../confirmationScreen/screens";

import RegistrationFormatter from "./formatter";

import styles from "./confirmStyles.module.scss";

export function ConfirmEnterprise(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const screenConfirmation = useSelector(
    (state: RootState) => state.screenStatus
  );
  const startupBasicData = useSelector(
    (state: RootState) => state.formCreateStartUp.basicData
  );
  const startupContactData = useSelector(
    (state: RootState) => state.formCreateStartUp.contacts
  );
  const startupAddressData = useSelector(
    (state: RootState) => state.formCreateStartUp.address
  );

  const formattedData = new RegistrationFormatter();
  const registrationService = new RegistrationService();

  const basicData: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "CNPJ",
      children: startupBasicData.cnpj,
    },
    {
      key: "2",
      label: "CEI",
      children: startupBasicData.cei,
    },
    {
      key: "3",
      label: "Nome Fantasia",
      children: startupBasicData.nome_fantasia,
    },
    {
      key: "4",
      label: "Razão Social",
      children: startupBasicData.razao_social,
    },
    {
      key: "5",
      label: "categoria",
      children: startupBasicData.categoria,
    },
    {
      key: "6",
      label: "Data da fundação",
      children: startupBasicData.data_fundacao,
    },
    {
      key: "7",
      label: "Porte",
      children: startupBasicData.porte,
    },
    {
      key: "8",
      label: "Numero de funcionários",
      children: startupBasicData.numero_funcionarios,
    },
    {
      key: "9",
      label: "CNAE",
      children: startupBasicData.cnae,
    },
    {
      key: "10",
      label: "Contabilidade",
      children: startupBasicData.contabilidade,
    },
    {
      key: "11",
      label: "Vlr. Pagamento",
      children: startupBasicData.valor_pagamento,
    },
  ];

  const contactData: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Telefone",
      children: startupContactData.phone,
    },
    {
      key: "4",
      label: "Celular",
      children: startupContactData.cellphone,
    },
    {
      key: "2",
      label: "Email",
      children: startupContactData.email,
    },
    {
      key: "3",
      label: "Site",
      children: startupContactData.site,
    },
  ];

  const addressData: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "CEP",
      children: startupAddressData.cep,
    },
    {
      key: "2",
      label: "Logradouro",
      children: startupAddressData.street,
    },
    {
      key: "3",
      label: "Número",
      children: startupAddressData.number,
    },
    {
      key: "4",
      label: "Complemento",
      children: startupAddressData.complemento,
    },
    {
      key: "5",
      label: "Bairro",
      children: startupAddressData.neighborhood,
    },
    {
      key: "6",
      label: "Cidade",
      children: startupAddressData.city,
    },
    {
      key: "7",
      label: "Estado",
      children: startupAddressData.state,
    },
  ];

  const ownerData: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Nome",
      children: startupBasicData.nome_responsavel,
    },
    {
      key: "2",
      label: "Cargo",
      children: startupBasicData.cargo_responsavel,
    },
  ];

  const handleBack = () => {
    navigate("/registrations/new-enterprise");
  };

  const handleConfirm = async () => {
    dispatch(setScreenConfirmation("pending"));
    dispatch(setFeatureScreenConfirmation("Cadastro de Empresa"));
    try {
      const dataFormatted = formattedData.startUpCreate({
        ...startupBasicData,
        ...startupContactData,
        ...startupAddressData,
      });

      await registrationService.registerEnterprise(dataFormatted);

      dispatch(setScreenConfirmation("approved"));
      dispatch(setFeatureScreenConfirmation("Cadastro de Empresa"));
      dispatch(setMessageScreenConfirmation("Empresa cadastrada com sucesso!"));
    } catch (error: any) {
      dispatch(setScreenConfirmation("rejected"));
      dispatch(setFeatureScreenConfirmation("Cadastro de Empresa"));
      dispatch(setMessageScreenConfirmation("Erro ao cadastrar empresa!"));
      dispatch(
        setSubMessageScreenConfirmation(
          error.message ?? "Erro inesperado, entre em contato com o suporte"
        )
      );
    }
  };

  return (
    <>
      {screenConfirmation.feature !== "Cadastro de Empresa" && 
      <>
        <Descriptions
          title="Confirme as informações inseridas | Dados Básicos:"
          items={basicData}
          labelStyle={{
            fontWeight: "bold",
            fontSize: "1rem",
          }}
          contentStyle={{
            fontSize: "1rem",
          }}
        />
        <Descriptions
          title="Informações de Contato:"
          items={contactData}
          labelStyle={{
            fontWeight: "bold",
            fontSize: "1rem",
          }}
          contentStyle={{
            fontSize: "1rem",
          }}
          style={{ marginTop: "1rem" }}
        />
        <Descriptions
          title="Endereço:"
          items={addressData}
          labelStyle={{
            fontWeight: "bold",
            fontSize: "1rem",
          }}
          contentStyle={{
            fontSize: "1rem",
          }}
          style={{ marginTop: "1rem" }}
        />
        <Descriptions
          title="Dados do Responsável:"
          items={ownerData}
          labelStyle={{
            fontWeight: "bold",
            fontSize: "1rem",
          }}
          contentStyle={{
            fontSize: "1rem",
          }}
          style={{ marginTop: "1rem" }}
        />
        <div className={styles.contentButtons}>
          <ButtonCustom
            label="Voltar"
            onClick={handleBack}
            style={{ backgroundColor: "var(--gray-100)", color: "#000" }}
          />
          <ButtonCustom label="Concluir" onClick={handleConfirm} />
        </div>
      </>
      }
      {screenConfirmation.feature === "Cadastro de Empresa" && (
        <ScreensConfirmation
          status={screenConfirmation.screenStatus}
          feature={screenConfirmation.feature}
          subTitle={screenConfirmation.subMessage}         
          navigateTo="/start-up"
          buttonError="Voltar para o início"
          buttonSuccess="Ver empresas cadastradas"
        />
      )}
    </>
  );
}
