import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AccessControl } from '../pages/access_control';
import { RegistrationI } from '../pages/registrationInnovationCenter/registrationI';
import { Home } from '../pages/home/home';
import { Financial } from '../pages/finacial/financial';
import { RegistrationsPage } from '../pages/registrations/companyRegistration';
import { UserRegistration } from '../pages/registrations/userRegistration';
import { StartUpsPage } from '../pages/startaUps/startUp';
import { Suppliers } from '../pages/suppliers/suppliers';
import { Sponsors } from '../pages/sponsors/sponsors';
import { EventsPage } from '../pages/events/events';
import { TiketsPage } from '../pages/tikets/tikets';
import { ConfirmEnterprise } from '../components/forms/enterprises/confirm/cornfirmEnterprise';
import { Rooms } from '../pages/rooms/rooms';
import { DamageControl } from '../pages/damage_control/damageControl';
import { NewAccessControl } from '../pages/new_access_control';
import { RecoveryPassword } from '../pages/recoverPassword';
import { NewTiketsPage } from '../pages/new_tikets';
import { TiketDetails } from '../pages/tickets_details/tiketDetails';
import { Users } from '../pages/configs/users/users';

import Layout from '../components/layout';
import PrivateWrapper from './PrivateWrapper';
import { Enterprises } from '../pages/configs/enterprise/enterpriseConfig';
import { ResetPassword } from '../pages/recoverPassword/resetPassword';
import { Details } from '../pages/startaUps/details';
import { PublicEventsPage } from '../pages/publicEvents/publicEvents';
import { InnovationCenter } from '../pages/configs/innovationCenter/innovationCenter';

function Router() {
  return (
      <Routes>
        <Route path="/new-access-control" element={<NewAccessControl />} />
        <Route path="/access-control" element={<AccessControl />} />
        <Route path="/recovery-password" element={<RecoveryPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route path="/registration/innovation-center" element={<RegistrationI />} />
        <Route path='/public-events' element={<PublicEventsPage />} />
        <Route path="/registrations/new-user/:user" element={<UserRegistration />} />
        <Route element={<PrivateWrapper />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/financial" element={<Financial />} />
            <Route path="/registrations/new-enterprise" element={<RegistrationsPage />} />
            <Route path="/registrations/new-enterprise/confirm" element={<ConfirmEnterprise />} />
            <Route path='/config/users' element={<Users />} />
            <Route path='/config/innovation-center-info' element={<InnovationCenter />} />
            <Route path="/config/enterprise" element={<Enterprises />} />
            <Route path="/start-up" element={<StartUpsPage />} />
            <Route path="/start-up/details/:id" element={<Details />} />
            <Route path="/suppliers" element={<Suppliers />} />
            <Route path="/sponsors" element={<Sponsors />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/tikets" element={<TiketsPage />} />
            <Route path="/new-tiket" element={<NewTiketsPage />} />
            <Route path="/tickets-details/:idTicket" element={<TiketDetails />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/damage_control" element={<DamageControl />} />
          </Route>
        </Route>
      </Routes>
  );
}

export default Router;