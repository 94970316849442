import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Address {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
    complemento: string;
}

interface Contacts {
    email: string;
    phone: string;
    cellphone: string;
    site: string;
}

interface IBasicData {
    id_initial_create_enterprise: string;
    cnpj: string;
    cnpj_instituition: string;
    name_innovation_center: string;
    cei: string;
    role: string;
    nome_fantasia: string;
    razao_social: string;
    categoria: string;
    data_fundacao: string;
    porte: string;
    numero_funcionarios: string;
    cnae: string;
    contabilidade: string;
    valor_pagamento: string;
    nome_responsavel: string;
    cargo_responsavel: string;
}

interface IStartup {
    basicData: IBasicData;
    address: Address;
    contacts: Contacts;
}

const initialState: IStartup = {
    basicData: {
        id_initial_create_enterprise: '',
        cnpj: '',
        cnpj_instituition: '',
        name_innovation_center: '',
        cei: '',
        role: '',
        nome_fantasia: '',
        razao_social: '',
        categoria: '',
        data_fundacao: '',
        porte: '',
        numero_funcionarios: '',
        cnae: '',
        contabilidade: '',
        valor_pagamento: '',
        nome_responsavel: '',
        cargo_responsavel: '',
    },
    address: {
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: '',
        complemento: '',
    },
    contacts: {
        email: '',
        phone: '',
        cellphone: '',
        site: '',
    },
};

const updateStartUpSlice = createSlice({
    name: 'updateStartUpForm',
    initialState,
    reducers: {
        setBasicData: (state, action: PayloadAction<Partial<IStartup['basicData']>>) => {
            state.basicData = {...state.basicData, ...action.payload};
        },
        setAddress: (state, action: PayloadAction<Partial<IStartup['address']>>) => {
            state.address = {...state.address, ...action.payload};
        },
        setContacts: (state, action: PayloadAction<Partial<IStartup['contacts']>>) => {
            state.contacts = {...state.contacts, ...action.payload};
        },
        resetFormStartup: () => initialState,
    },
});

export const {
    resetFormStartup,
    setBasicData,
    setAddress,
    setContacts,
} = updateStartUpSlice.actions;

export default updateStartUpSlice.reducer;