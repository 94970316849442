import moment from "moment";

function validarCNPJ(cnpj: any) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
  
    if (cnpj === '') return false;
    if (cnpj.length !== 14) return false;
    if (/^(\d)\1+$/.test(cnpj)) return false;
  
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
  
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
  
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0))) return false;
  
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1))) return false;
  
    return true;
  }

  function validaEmail(email: string) {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function validateDateRange(startDate: string, endDate: string) {
  
    // Verifica se startDate é hoje ou antes
    const isStartValid = moment(startDate).isSameOrBefore(moment(), 'day');
    
    // Verifica se endDate é depois de hoje
    const isEndValid = moment(endDate).isAfter(moment(startDate), 'day');
  
    return isStartValid && isEndValid;
  }

  function validateDateBeforeToday(date: string) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Ignora a hora atual, considera apenas a data
  
    const start = new Date(date);
    start.setHours(0, 0, 0, 0); // Normaliza a hora para comparação apenas da data
  
    // Verifica se startDate é hoje ou antes
    const isStartValid = start < today;
  
    return isStartValid;
  }

  function validatePassword(password: string) {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    return re.test(password);
  }

  function isValidUrl(url: string) {
    const pattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return pattern.test(url);
  }

  function validateBrazilianPhoneNumber(phone: string) {
    const phoneRegex = /^(?:(?:\+|00)?55\s?)?(?:\(?\d{2}\)?\s?)?(?:[2-5]\d{3}\-?\d{4}|9[1-9]\d{3}\-?\d{4})$/;
  
    return phoneRegex.test(phone);
  }

  function validateCEP(cep: string) {
    const cepRegex = /^\d{5}-?\d{3}$/;
  
    return cepRegex.test(cep);
  }

  export { 
    validarCNPJ,
    validaEmail,
    validateDateRange,
    validateDateBeforeToday,
    validatePassword,
    isValidUrl,
    validateBrazilianPhoneNumber,
    validateCEP
};