import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventsServices } from '../services/apis/eventsServices';
import { IEvent } from '../types/eventTypes';

interface EventState {
    event: any;
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
}

const initialState: EventState = {
    event: null,
    status: 'idle',
    error: null
};

export const fetchEventById = createAsyncThunk<IEvent, string, { rejectValue: string }>(
    'event/fetchById',
    async (eventId: string, { rejectWithValue }) => {
        try {
            const eventService = new EventsServices();
            const response = await eventService.getEventById(eventId);
            return response;
        } catch (error) {
            return rejectWithValue('Failed to fetch event');
        }
    }
);

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        clearEvent(state) {
            state.event = null;
            state.status = 'idle';
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEventById.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchEventById.fulfilled, (state, action) => {
                state.status = 'idle';
                state.event = action.payload;
            })
            .addCase(fetchEventById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const { clearEvent } = eventSlice.actions;
export default eventSlice.reducer;