import { 
    useEffect, 
    useState 
} from "react";
import { Alert, Modal, Spin } from "antd";

import { Ticket } from "./tiketDetails";
import { InputTextFilter } from "../../components/inputTextFilter/inputTextFilter";
import { status_tikets } from "../../constants/tikets";
import { TiketsServices } from "../../services/apis/tiketsServices";

import styles from "./TicketDetails.module.scss";
import { InputSelect } from "../../components/inputSelect/inputTextFilter";

interface ModalFormUpdateProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    ticket: Ticket | undefined;
    feature: string;
    getTikets: () => void;
}

export function ModalFormUpdate({ 
    ticket, 
    isModalOpen,
    setIsModalOpen,
    feature,
    getTikets
}: ModalFormUpdateProps) {
    const [value, setValue] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        status: false,
        message: ''
    });

    const changeTicket = async () => {
        const ticketService = new TiketsServices();
        setLoading(true);
        try {
            if (feature === 'status') {
                await ticketService.updateTiket(ticket?.id, {
                    status: value
                });
            } else {
                await ticketService.updateTiket(ticket?.id, {
                    priority: value
                });
            }

            setLoading(false);
            setIsModalOpen(false);
            getTikets();
        } catch (error: any) {
            setLoading(false);
            setError({
                status: true,
                message: error.message
            });
        }
    };

    const handleOk = () => {
      changeTicket();
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const getOptions = (feature: string) => {
        switch (feature) {
            case 'status':
                return status_tikets.slice(1).map((item) => ({ value: item.name, label: item.name }));
            case 'prioridade':
                return [
                    { value: 'baixa', label: 'Baixa' },
                    { value: 'media', label: 'Média' },
                    { value: 'alta', label: 'Alta' },
                ];
            default:
                return [];
        }
    };

    const getValues = (feature: string) => {
        switch (feature) {
            case 'status':
                return setValue(ticket?.status ?? '');
            case 'prioridade':
                return setValue(ticket?.priority ?? '');
            default:
                return '';
        }
    };

    const handleSelected = (value: string) => {
        setValue(value);
    }

    useEffect(() => {
        getValues(feature);
    }, [feature]);

    return (
        <Modal 
            title={`Atualizar ${feature} do chamado #${ticket?.id}`} 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
        >
            {!loading && 
            !error.status &&
            <InputSelect 
                label="Title" 
                value={value} 
                onChange={handleSelected}
                options={getOptions(feature)}
                required={false}
            />}
            {!loading && error.status && <Alert message={error.message} type="error" showIcon />}
            <Spin 
              tip="Loading" 
              size="large"
              className={styles.spinLoading}
              spinning={loading}
            >
              <div className={styles.contentSpin}/>
            </Spin>
        </Modal>
    )
}
