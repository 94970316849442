import { Tag } from "antd";

interface ITagProps {
    color: string;
    text: string;
}
export function Tags({color, text}: ITagProps) {
    return (
        <Tag color={color}>{text}</Tag>
    );
}