import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AccessControl as AccessControlClass } from "../services/apis/accessControlServices";
import Cookies from "js-cookie";

const PrivateWrapper = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);  // null indica estado não inicializado
  const [firstAccess, setFirstAccess] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false); // false indica que o acesso do usuário está bloqueado
  const [onlyEvent, setOnlyEvent] = useState<boolean>(false);

  useEffect(() => {
    const profile = Cookies.get("profile");
    const verifyToken = async () => {
      const token = Cookies.get("access_token");
      const parseProfile = profile ? JSON.parse(profile) : null;
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await new AccessControlClass().validateToken(token);
        //verificar se o token é válido e pertence ao mesmo usuario logado
        if (response.data?.data?.user?.id !== parseProfile[0].id_auth) {
          setIsAuthenticated(false);
          return
        }
        setIsAuthenticated(!!response?.data);  // Set true if response.data exists, false otherwise
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    const checkFirstAccess = async () => {
      const validRoles = ['startup', 'ADM', 'ATENDIMENTO'];
      const user = Cookies.get("user");
      const userParsed = user ? JSON.parse(user) : null; 
      const profileParsed = profile ? JSON.parse(profile) : null;

      if ((userParsed?.accessNumber === 1) && (validRoles.includes(profileParsed[0].role)) && (!profileParsed[0].change_temporary_password)) {
        setFirstAccess(true);
      }
    };

    const checkStatus = async () => {
      const profileParsed = profile ? JSON.parse(profile) : null;

      if (profileParsed && profileParsed[0]?.status === 'active') {
        setStatus(true);
      }
    };

    const checkAccessRole = async () => {
      const profileParsed = profile ? JSON.parse(profile) : null;
      if (profileParsed && profileParsed[0]?.role === 'EVENTOS') {
        setOnlyEvent(true);
      }
    }

    checkStatus();
    verifyToken();
    checkFirstAccess();
    checkAccessRole();
  }, []);

  // Enquanto o estado de autenticação não é verificado, podemos optar por não renderizar nada ou mostrar um spinner
  if (isAuthenticated === null) {
    return <div>Loading...</div>; // ou um componente de spinner/loader
  }

  // Redirecionar para a tela de login se não estiver autenticado
  if (!isAuthenticated || !status) {
    return <Navigate to="/new-access-control" replace={true} />;
  }

  if (firstAccess) {
    return <Navigate to="/recovery-password" replace={true} />;
  }

  // if (onlyEvent) {
  //   return <Navigate to="/events" replace={true} />;
  // }

  // Renderizar os componentes filhos se o usuário estiver autenticado
  return <Outlet />;
};

export default PrivateWrapper;