const portesDeEmpresa = [
    "Microempresa",    // Receita bruta anual igual ou inferior a R$ 360.000,00
    "Pequeno Porte", // Receita bruta anual superior a R$ 360.000,00 e igual ou inferior a R$ 4.800.000,00
    "Médio Porte",   // Receita bruta anual acima de R$ 4.800.000,00 até R$ 300.000.000,00
    "Grande Porte"   // Receita bruta anual acima de R$ 300.000.000,00
];

const categoriasDeEmpresa = [
    "Residentes",
    "Avulso",
    "Membro Virtual"
];

export { portesDeEmpresa, categoriasDeEmpresa };