import { 
    Button,
    Col,
    Form, 
    Input, 
    Row
} from "antd";
import { Trash } from "lucide-react";

import styles from "./events.module.scss";

interface DatesWithHoursProps {
    removeDate: (index: number) => void;
    index: number;
    defaultValue?: any;
}

export function DatesWithHours({ 
  removeDate, 
  index,
}: Readonly<DatesWithHoursProps>) {

    return (
        <div className={styles.formRow}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col flex='auto'>
              <Form.Item
                className={styles.formItem}
                label={"Data de início " + (index + 1)}
                name={"initialDate" + index}
                rules={[
                  { required: true, message: "Campo data de inicio é obrigatório" },
                ]}
              >
                <Input type="date" />
              </Form.Item>
            </Col>
            <Col flex='auto'>
              <Form.Item
                className={styles.formItem}
                label="Hora de início"
                name={"initialTime" + index}
                rules={[
                  { required: true, message: "Campo hora de inicio é obrigatório" },
                ]}
              >
                <Input type="time" />
              </Form.Item>
            </Col>
            <Col flex='auto'>
              <Form.Item
                className={styles.formItem}
                label="Hora de fim"
                name={"endTime" + index}
                rules={[
                  { required: true, message: "Campo data de fim é obrigatório" },
                ]}
              >
                <Input type="time" />
              </Form.Item>
            </Col>
          </Row>
            <Button 
                type="primary" 
                danger
                onClick={() => removeDate(index)}
                icon={<Trash size={15}/>} 
                style={{
                    width: 30,
                    height: 30,
                }}
            />
        </div>
    )
}