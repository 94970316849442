
class RegistrationFormatter {
    innovationCenterFormatter(payload: any) {
       return {
            email: payload.credentialData.email_credential,
            password: payload.credentialData.password_credential,
            role: "innovation",
            role_ref: "master",
            cnpj: payload.basicData.cnpj,
            name_innovation_center: payload.basicData.name,
            description: payload.basicData.description,
            phone: payload.basicData.phoneNumber,
            website: payload.basicData.website,
            address: {
                street: payload.addressData.street,
                number: payload.addressData.number,
                district: payload.addressData.district,
                city: payload.addressData.city,
                state: payload.addressData.state,
                zip_code: payload.addressData.zip_code,
                country: payload.addressData.country
            }
        }
    }
}

export default RegistrationFormatter;