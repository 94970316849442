import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { resetFormData, setCredentialData } from "../../../store/formDataSlice";
import { setCurrentStep } from "../../../store/currentStepSlice";
import { ButtonCustom } from "../../customButtom/buttomCustom";
import { InputText } from "../../inputText/inputText";
import { validaEmail, validatePassword } from "../../../services/validation.service";
import { setFeatureScreenConfirmation, setMessageScreenConfirmation, setScreenConfirmation, setSubMessageScreenConfirmation } from "../../../store/screenConfirmationSlice";
import RegistrationFormatter from "../confirmInformations/formatter";
import { RegistrationService } from "../../../services/apis/registrationSevice";

import styles from "./credentialDataStyles.module.scss";

export function CredentialDataForm() {
    const dispatch = useDispatch(); 
    const innovationCenter = useSelector((state: RootState) => state.formData);
    const currentStep = useSelector((state: RootState) => state.currentStep.currentStep);
    const formattedData = new RegistrationFormatter();
    const registrationService = new RegistrationService();
    const [errorEmail, setErrorEmail] = useState({
        error: false,
        message: ""
    });
    const [errorPassword, setErrorPassword] = useState({
        error: false,
        message: ""
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        dispatch(setCredentialData({ [name]: value }));
    }

    function ListInfoSenha() {
        return (
            <ul className={styles.listInfos}>
                <li>Deve conter no mínimo 6 caracteres</li>
                <li>Deve conter pelo menos uma letra maiúscula</li>
                <li>Deve conter pelo menos uma letra minúscula</li>
                <li>Deve conter pelo menos um número</li>
            </ul>
        )
    }

    const handleNext = async () => {        
        try {
            if (innovationCenter.credentialData.email_credential === "") { 
                setErrorEmail({
                    error: true,
                    message: "E-mail é obrigatório"
                });
                return;
            }
    
            if (!validaEmail(innovationCenter.credentialData.email_credential)) {
                setErrorEmail({
                    error: true,
                    message: "E-mail inválido"
                });
                return;
            }
    
            if (innovationCenter.credentialData.password_credential === "") { 
                setErrorPassword({
                    error: true,
                    message: "Senha é obrigatória"
                });
                return;
            }
    
            if (innovationCenter.credentialData.password_credential !== innovationCenter.credentialData.password_confirmation_credential) {
                setErrorPassword({
                    error: true,
                    message: "Senhas não conferem"
                });
                return;
            }
    
            if (!validatePassword(innovationCenter.credentialData.password_credential)) {
                setErrorPassword({
                    error: true,
                    message: "Senha inválida, clique no ícone de informação ! para mais detalhes"
                });
                return;
            }
    
            setErrorEmail({
                error: false,
                message: ""
            });
            setErrorPassword({
                error: false,
                message: ""
            });

            dispatch(setCurrentStep(currentStep + 1));
            dispatch(setScreenConfirmation("pending"));
            const formattedRegistration =
              formattedData.innovationCenterFormatter(innovationCenter);
            const responseRegister =
              await registrationService.registerInnovationCenter(
                formattedRegistration
              );
            dispatch(setScreenConfirmation("approved"));
            dispatch(setFeatureScreenConfirmation("Cadastro"));
            dispatch(setMessageScreenConfirmation("Cadastro realizado com sucesso"));
            dispatch(
            setSubMessageScreenConfirmation(
              "Valide seu e-mail para acessar o sistema"
            )
            );
    
            dispatch(resetFormData());
            return responseRegister;
        } catch (error: any) {
            dispatch(setScreenConfirmation("rejected"));
            dispatch(setFeatureScreenConfirmation("Cadastro"));
            dispatch(setMessageScreenConfirmation("Erro ao realizar o cadastro"));
            dispatch(
              setSubMessageScreenConfirmation(
                error.message ?? "Erro inesperado, entre em contato com o suporte"
              )
            );
        }
    }

    const handleBack = () => {
        dispatch(setCurrentStep(currentStep - 1));
    }

    return (
        <>
            <div className={styles.containerBasicData}>
                <div className={styles.grupo1}>
                    <InputText 
                        label="E-mail"
                        name="email_credential"
                        type="text"
                        placeholder="Digite um e-mail para acesso ao sistema"
                        onChange={handleChange}
                        value={innovationCenter.credentialData.email_credential}
                        error={errorEmail.error}
                        errorMensage={errorEmail.message}
                        required
                    />
                </div>
                <div className={styles.grupo2}>
                    <InputText 
                        label="Senha"
                        name="password_credential"
                        type="password"
                        placeholder="Digite uma senha para acesso ao sistema"
                        onChange={handleChange}
                        value={innovationCenter.credentialData.password_credential}
                        info={<ListInfoSenha />}
                        error={errorPassword.error}
                        errorMensage={errorPassword.message}
                        required
                    />
                </div>
                <div className={styles.grupo3}>
                    <InputText 
                        label="Confirmação de senha"
                        name="password_confirmation_credential"
                        type="password"
                        placeholder="Confirme a senha para acesso ao sistema"
                        onChange={handleChange}
                        value={innovationCenter.credentialData.password_confirmation_credential}
                        info={<ListInfoSenha />}
                        error={errorPassword.error}
                        errorMensage={errorPassword.message}
                        required
                    />
                </div>
            </div>
            <div className={styles.contentButtons}>
                <ButtonCustom 
                  label="Voltar"
                  onClick={handleBack}
                  style={{backgroundColor: "var(--gray-100)", color: "#000"}}
                />
                <ButtonCustom 
                  label="Confirmar" 
                  onClick={handleNext}
                />
            </div>
        </>
    );
}