import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EyeIcon, EyeOff } from "lucide-react";
import { AccessControl as AccessControlClass } from "../../services/apis/accessControlServices";
import { useNavigate, useParams } from "react-router-dom";

import * as Yup from "yup";

import styles from "./newPasswordStyles.module.scss";
import Cookies from "js-cookie";
import { notification, Spin } from "antd";
import { NotificationType } from "../../types/commonTypes";

// Esquema de validação utilizando Yup
const LoginSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, "A nova senha deve ter pelo menos 6 caracteres")
    .required("A nova senha é obrigatória"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), undefined], "As senhas devem ser iguais")
    .equals([Yup.ref("newPassword")], "As senhas devem ser iguais")
    .required("A confirmação da nova senha é obrigatória"),
});

export function ResetPassword(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    const accessControlService = new AccessControlClass();
    try {
      const resp = await accessControlService.resetPasswordByAdmin({
        id: params.id ?? "",
        password: values.newPassword,
      });
      setIsSubmitting(false);
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Senha alterada com sucesso"
      );
      setTimeout(() => {
        const currentUrl = new URL(window.location.href);
        // Identifica o domínio base dependendo do ambiente (com ou sem subdomínios)
        const domainParts = currentUrl.hostname.split(".");
        let baseDomain = currentUrl.hostname; // Assume que é o domínio completo

        // Verifica se o domínio tem partes suficientes para considerar a existência de um subdomínio
        baseDomain = domainParts.slice(-3).join("."); // Pega apenas a parte principal do domínio

        // Subdomínio desejado
        const newSubdomain = resp.subdomain; // Substitua 'novoSubdominio' pelo subdomínio real que você deseja
        currentUrl.hostname = `${newSubdomain}.${baseDomain}`;

        // Atualiza o caminho para a nova rota desejada
        currentUrl.pathname = "/new-access-control";
        // Redireciona para a nova URL
        window.location.href = currentUrl.toString();
      }, 3000);
    } catch (error: any) {
      setIsSubmitting(false);
      openNotificationWithIcon(
        "error",
        "Erro ao alterar senha",
        error.message ?? "Não foi possível alterar a senha"
      );
    }
  };
  return (
    <div className={styles.container}>
      {contextHolder}
      <>
        <h1>Alterar senha</h1>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form}>
              <label htmlFor="newPassword">Nova senha</label>
              <Field type="password" name="newPassword">
                {({ field }: any) => (
                  <div className={styles.passwordField}>
                    <input
                      maxLength={10}
                      type={!showNewPassword ? "password" : "text"}
                      {...field}
                    />
                    {!showNewPassword ? (
                      <EyeOff
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        size={24}
                      />
                    ) : (
                      <EyeIcon
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        size={24}
                      />
                    )}
                  </div>
                )}
              </Field>
              <ErrorMessage
                name="newPassword"
                component="div"
                className={styles.error}
              />

              <label htmlFor="confirmNewPassword">Confirme a nova senha</label>
              <Field type="password" name="confirmNewPassword">
                {({ field }: any) => (
                  <div className={styles.passwordField}>
                    <input
                      maxLength={10}
                      type={!showConfirmNewPassword ? "password" : "text"}
                      {...field}
                    />
                    {!showConfirmNewPassword ? (
                      <EyeOff
                        onClick={() =>
                          setShowConfirmNewPassword(!showConfirmNewPassword)
                        }
                        size={24}
                      />
                    ) : (
                      <EyeIcon
                        onClick={() =>
                          setShowConfirmNewPassword(!showConfirmNewPassword)
                        }
                        size={24}
                      />
                    )}
                  </div>
                )}
              </Field>
              <ErrorMessage
                name="confirmNewPassword"
                component="div"
                className={styles.error}
              />

              <button type="submit" disabled={isSubmitting}>
                Enviar
              </button>
            </Form>
          )}
        </Formik>
      </>
      <Spin spinning={isSubmitting} fullscreen={true} size="large" />
    </div>
  );
}
