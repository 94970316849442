import { clearStringOnlyNumbers } from "../../../libs/masks";

class InnovationCenterFormatter {
    innovationCenterUpdated(payload: any) {        
       return {
            cnpj: clearStringOnlyNumbers(payload.cnpj),
            name_innovation_center: payload.name_innovation_center,
            description: payload.description,
            address: {
                cep: clearStringOnlyNumbers(payload.address_cep),
                street: payload.address_street,
                number: payload.address_number,
                complemento: payload.address_complemento,
                neighborhood: payload.address_neighborhood,
                city: payload.address_city,
                state: payload.address_state,
            },
            contacts: {
                email: payload.contact_email,
                phone: clearStringOnlyNumbers(payload.contact_phone),
            },
            president: {
                name: payload.president_name,
                email: payload.president_email,
                phone: payload.president_phone,
                biginning_of_term: payload.president_biginning_of_term,
                end_of_term: payload.president_end_of_term,
            }
        }
    }
}

export default InnovationCenterFormatter;