import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LayoutState {
  sidebar: boolean;
  activeDrawer: boolean;
}

const initialState: LayoutState = {
  sidebar: false,
  activeDrawer: false,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.sidebar = !state.sidebar;
    },
    toggleDrawer(state) {
      state.activeDrawer = !state.activeDrawer;
    },
  },
});

export const { toggleSidebar, toggleDrawer } = layoutSlice.actions;
export default layoutSlice.reducer;
