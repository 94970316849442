import React from "react";
import { Alert, Popconfirm, Spin } from "antd";
import type { PopconfirmProps  } from "antd";
import { Eye, EyeOff, Info } from "lucide-react";

import styles from './inputTextStyle.module.scss';

interface InputTextProps {
  label?: string;
  info?: React.ReactNode;
  name?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMensage?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  id?: string;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  title?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  style?: React.CSSProperties;
  styleLabel?: React.CSSProperties;
  styleContainer?: React.CSSProperties;
  loading?: boolean;
}

export function InputText({
  label,
  info,
  name,
  type,
  placeholder,
  value,
  onChange,
  onKeyPress,
  error = false,
  errorMensage,
  onBlur,
  required,
  id,
  pattern,
  title,
  autoComplete,
  inputRef,
  inputProps,
  labelProps,
  loading,
  styleLabel,
  style,
  styleContainer,
  maxLength,
  disabled = false,
}: InputTextProps): JSX.Element {
  const [infoVisible, setInfoVisible] = React.useState(false);
  const [passWordVisible, setPassWordVisible] = React.useState(false);
  const inputId = id || name;
  const inputType = type || 'text';
  const inputPattern = pattern ? pattern : '';
  const inputTitle = title ? title : '';
  const inputAutoComplete = autoComplete ? autoComplete : '';
  const inputPlaceholder = placeholder ? placeholder : '';
  const inputLabel = label ? label : '';
  const inputName = name ? name : '';
  const inputValue = value ? value : '';
  const inputOnChange = onChange ? onChange : () => {};
  const inputOnBlur = onBlur ? onBlur : () => {};
  const inputInputRef = inputRef ? inputRef : null;

  const handleInfoVisible = () => {
    setInfoVisible(!infoVisible);
  }

  const handlePassWordVisible = () => {
    setPassWordVisible(!passWordVisible);
  }


  return (
    <div 
      className={styles.container}
      style={styleContainer ? styleContainer : {}}
      >
        <label 
          htmlFor={inputId} 
          className={styles.labelInput} 
          style={error ? {color: 'var(--red-500)'} : styleLabel ? styleLabel : {}}
          {...labelProps}
        >
            {inputLabel}{required && ' *'}
            {info && 
            <Info 
              className={styles.iconInfo} 
              style={error ? {color: 'var(--red-500)'} : {}}
              onClick={handleInfoVisible}
            />}
        </label>
        {/*error && 
        <Alert
          message="Erro"
          description={errorMensage || 'Campo obrigatório'}
          type="error"
          showIcon
            />*/}
        {info &&
        <Alert
          message="A Senha"
          description={info}
          type="info"
          className={styles.infoAlert}
        />
        }
        <Popconfirm
          open={error}
          title="Erro"
          description={errorMensage || 'Campo obrigatório'}
          icon={<Info color="red" size={20} style={{marginRight: 5}}/>}
          okButtonProps={{style: {display: 'none'}}}
          cancelButtonProps={{style: {display: 'none'}}}         
        >
        <div 
          className={styles.contentInput}
          style={error ? {border: '2px solid var(--red-500)'} : style ? style : {}}
        >
          <input
              id={inputId}
              type={inputType === 'password' ? (passWordVisible ? 'text' : 'password') : inputType}
              pattern={inputPattern}
              title={inputTitle}
              autoComplete={inputAutoComplete}
              placeholder={inputPlaceholder}
              name={inputName}
              value={inputValue}
              onChange={inputOnChange}
              onKeyDown={onKeyPress}
              onBlur={inputOnBlur}
              ref={inputInputRef}
              maxLength={maxLength}
              required={required}
              disabled={disabled}
              {...inputProps}
          />
          {loading && <Spin />}
          {inputType === 'password' && passWordVisible && <Eye onClick={handlePassWordVisible}/>}
          {inputType === 'password' && !passWordVisible && <EyeOff onClick={handlePassWordVisible}/>}
        </div>
        </Popconfirm>
    </div>
  )
}