import { Cascader, Input } from "antd";
import { MultipleCascaderProps } from "antd/es/cascader";
import { ButtonCustom } from "../customButtom/buttomCustom";

import styles from "./headerStyle.module.scss";
import { CircleFadingPlus, Plus } from "lucide-react";

const { Search } = Input;

interface HeaderFiltersProps {
  text: string;
  changeButton: () => void;
  changeSearch?: (value: string) => void;
  changeCascader?: (value: any) => void;
  disabledSearch?: boolean;
  disabledCascade?: boolean;
  optionsCascader?: Option[];
}

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
  disableCheckbox?: boolean;
}

export function HeaderFilters({
  text,
  changeButton,
  changeSearch,
  disabledSearch = true,
  disabledCascade = true,
  optionsCascader,
  changeCascader,
}: HeaderFiltersProps) {
  const onChange: MultipleCascaderProps<Option>["onChange"] = (value) => {
    changeCascader && changeCascader(value);
  };

  return (
    <div className={styles.containerHeader}>
      <div className={styles.contentSearch}>
        <Search
          placeholder="Digite aqui os termos da sua pesquisa"
          disabled={disabledSearch}
          onChange={(e) => {
            changeSearch && changeSearch(e.target.value);
          }}
        />
      </div>
      <div className={styles.contentFilters}>
        <div className={styles.boxCascader}>
          <Cascader
            style={{ width: "100%" }}
            options={optionsCascader}
            onChange={onChange}
            multiple
            maxTagCount="responsive"
            placeholder="Selecione os filtros"
            disabled={disabledCascade}
          />
        </div>
        <div className={styles.boxBtnNewStartUp}>
          <ButtonCustom
            icon={CircleFadingPlus}
            label={text}
            onClick={changeButton}
            style={{
              width: "100%",
              height: "2.25rem",
              fontSize: "1rem",
              padding: "0 1rem",
            }}
          />
        </div>
      </div>
    </div>
  );
}
