import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ScreenConfirmationState {
    screenStatus: string;
    feature: string;
    message: string;
    subMessage: string;
}

const initialState: ScreenConfirmationState = {
  screenStatus: '',
  feature: '',
  message: '',
  subMessage: '',
};

const ScreenSlice = createSlice({
  name: 'screenStatus',
  initialState,
  reducers: {
    setScreenConfirmation: (state, action: PayloadAction<string>) => {
      { state.screenStatus = action.payload };
    },
    setFeatureScreenConfirmation: (state, action: PayloadAction<string>) => {
      { state.feature = action.payload };
    },
    setMessageScreenConfirmation: (state, action: PayloadAction<string>) => {
      { state.message = action.payload };
    },
    setSubMessageScreenConfirmation: (state, action: PayloadAction<string>) => {
      { state.subMessage = action.payload };
    },
    resetScrennConfirmation: () => initialState,
  },
});

export const { 
  setScreenConfirmation, 
  setFeatureScreenConfirmation, 
  setMessageScreenConfirmation, 
  setSubMessageScreenConfirmation,
  resetScrennConfirmation
 } = ScreenSlice.actions;
export default ScreenSlice.reducer;