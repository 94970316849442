import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";
import { AddressDataForm } from "../../components/forms/address/address";
//import { ContactDataForm } from "../../components/forms/contact/contact";
import { CredentialDataForm } from "../../components/forms/credentials/credentialData";
import { BasicDataForm } from "../../components/forms/innovationCenters/basicData";
//import { PresidentDataForm } from "../../components/forms/innovationCenters/presidentData";
import { StepView } from "../../components/stepByStep/stepView";
import { AddressService } from "../../services/apis/addressService";
import { ConfirmInformationsForm } from "../../components/forms/confirmInformations/confirmInfo";
import { ScreensConfirmation } from "../../components/confirmationScreen/screens";
import { estados } from "../../constants/addressConstants";

import styles from "./registration.styles.module.scss";

export function RegistrationI() {
  const states = estados;
  const currentStep = useSelector((state: RootState) => state.currentStep.currentStep);
  const innovationCenter = useSelector((state: RootState) => state.formData);
  const screenConfirmation = useSelector((state: RootState) => state.screenStatus);
  const addressService = new AddressService();

  const [ufs, setUfs] = useState<{id: number, sigla: string, nome: string}[]>([]);

  const getAllUfs = async () => {
    try {
      const response = await addressService.getUfs();
      setUfs(response);
    } catch (error: any) {
      setUfs(states);
      return;
    }
  }

  const steps = [
    {
      title: "Dados básicos",
      description: "",
    },
    {
      title: "Endereço",
      description: "",
    },
    {
      title: "Credenciais",
      description: "",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      getAllUfs();   
    }, 1000);
  }, []);

  return (
    <div className={styles.container}>
      <h1>Cadastrar centro de inovação</h1>
      {!(currentStep === 3 && screenConfirmation) && <StepView 
        step={steps} 
        currentStep={currentStep}
      />}
      {currentStep === 0 && <BasicDataForm />}
      {currentStep === 1 && <AddressDataForm optionsUf={ufs}/>}
      {/*currentStep === 2 && <ContactDataForm />*/}
      {/*currentStep === 3 && <PresidentDataForm />*/}
      {currentStep === 2 && <CredentialDataForm />}
      {/*currentStep === 5 && <ConfirmInformationsForm />*/}
      {(currentStep === 3 && screenConfirmation) && <ScreensConfirmation status={screenConfirmation.screenStatus} feature={screenConfirmation.feature} subTitle={screenConfirmation.subMessage} navigateTo="/new-access-control"/>}
    </div>
  );
}