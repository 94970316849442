import { useEffect, useState } from "react";
import { EnterpriseForm } from "../../components/forms/enterprises/enterpriseForm";
import { AddressService } from "../../services/apis/addressService";
import { estados } from "../../constants/addressConstants";

export function RegistrationsPage() {
    const states = estados; 
    const addressService = new AddressService();

    const [ufs, setUfs] = useState<{id: number, sigla: string, nome: string}[]>([]);

    const getAllUfs = async () => {
      try {
        const response = await addressService.getUfs();
        setUfs(response);
      } catch (error: any) {
        setUfs(states);
        return;
      }
    }

    useEffect(() => {
        setTimeout(() => {
          getAllUfs();   
        }, 1000);
    }, []);

    return (
        <EnterpriseForm 
            optionsUfs={ufs}
        />
    );
}