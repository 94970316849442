import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Defina o tipo para o estado inicial
interface WindowDimensions {
  width: number;
  height: number;
}

// Estado inicial
const initialState: WindowDimensions = {
  width: window.innerWidth,
  height: window.innerHeight,
};

export const windowSlice = createSlice({
  name: 'window',
  initialState,
  reducers: {
    setDimensions: (state, action: PayloadAction<WindowDimensions>) => {
      state.width = action.payload.width;
      state.height = action.payload.height;
    },
  },
});

// Exportar as ações
export const { setDimensions } = windowSlice.actions;

// Exportar o reducer
export default windowSlice.reducer;