import axios, { isAxiosError, AxiosResponseHeaders } from "axios";
import { IRegistrationInnovationCenter } from "../../types/registration";
import { api } from "./configs";

export class RegistrationService {
    registerInnovationCenter = async (data: IRegistrationInnovationCenter) => {
        try {
            const response = await api.post('/registration/create/innovation-center', data);
            return response.data;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    registerEnterprise = async (data: any) => {
        try {
            const response = await api.post('/startup/create', data);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.log(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.log('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    readStartup = async (cnpj_owner: string) => {
        try {
            const response = await api.post(`/startup/read-by-owner-cnpj`, {
                cnpj_owner
            });
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    deleteStartup = async (id: string) => {
        try {
            const response = await api.delete(`/startup/delete/${id}`);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    upDateStartup = async (id: number, data: any) => {
        try {
            const response = await api.put(`/startup/update/${id}`, data);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }
}