import Cookies from "js-cookie";
import { clearStringOnlyNumbers, removeMoneyMask } from "../../../libs/masks";

class RegistrationFormatter {
    startUpUpdated(payload: any) {
       return {
            cnpj: clearStringOnlyNumbers(payload.cnpj),
            cei: payload.cei,
            nome_fantasia: payload.nome_fantasia,
            razao_social: payload.razao_social,
            categoria: payload.categoria,
            data_fundacao: payload.data_fundacao,
            porte: payload.porte,
            numero_funcionarios: payload.numero_funcionarios,
            cnae: payload.cnae,
            contabilidade: payload.contabilidade,
            valor_pagamento: removeMoneyMask(payload.valor_pagamento),
            endereco: {
                street: payload.street,
                number: payload.number,
                neighborhood: payload.neighborhood,
                city: payload.city,
                state: payload.state,
                cep: clearStringOnlyNumbers(payload.cep),
                complemento: payload.complemento
            },
            contatos: {
                email: payload.email,
                telefone: clearStringOnlyNumbers(payload.phone),
                celular: clearStringOnlyNumbers(payload.cellphone),
                site: payload.site
            },
            owners: {
                nome_responsavel: payload.nome_responsavel,
                cargo: payload.cargo_responsavel
            }
        }
    }
}

export default RegistrationFormatter;