import axios from "axios";
import { api } from "./configs";

export class StartUpService {
    requestPasswordReset = async (id: string) => {
        try {
            const response = await api.get(`/startup/request-reset-password?id=${id}`);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    readStartupById = async (id: string) => {
        try {
            const response = await api.post(`/startup/read-by-id`, {
                id
            });
            return response.data[0];
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }
}