import { configureStore, current } from '@reduxjs/toolkit'
import windowSlice from './windowSlice'
import formDataReducer from './formDataSlice'
import currentStepReducer from './currentStepSlice'
import screenConfirmationSlice from './screenConfirmationSlice'
import formCreateStartUpSlice from './formCreateStartUpSlice'
import tiketsCreateSlice from './tiketsSlice'
import notificationsReducer from './notificationSlice'
import styleReducer from './styleSlice'
import layoutSliceReducer from './layoutSlice'
import commonSlice from './commonSlice'
import eventSlice from './eventSlice'
import updateStartUpSlice from './updateStartUpSlice'
import updateInnovationCenterSlice from './updateInnovationCenterSlice'

export const store = configureStore({
  reducer: {
    window: windowSlice,
    formData: formDataReducer,
    currentStep: currentStepReducer,
    screenStatus: screenConfirmationSlice,
    formCreateStartUp: formCreateStartUpSlice,
    tiketsCreate: tiketsCreateSlice,
    notifications: notificationsReducer,
    styleSlice: styleReducer,
    layoutSlice: layoutSliceReducer,
    commonSlice: commonSlice,
    eventSlice: eventSlice,
    updateStartUpSlice: updateStartUpSlice,
    updateInnovationCenterSlice: updateInnovationCenterSlice,
  },
})

// Inferir o tipo `RootState` e `AppDispatch` a partir da store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch