import { LucideIcon } from "lucide-react";
import styles from "./buttomStyle.module.scss";
import { Button } from "antd";

interface ButtonCustomProps {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  ref?: React.RefObject<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  icon?: LucideIcon;
  [key: string]: any;
}

export function ButtonCustom({
  label,
  type,
  onClick,
  onKeyPress,
  ref,
  disabled,
  className,
  id,
  icon: Icon,
  ...props
}: ButtonCustomProps): JSX.Element {
  const buttonId = id || label;

  return (
    <Button
      ref={ref}
      id={buttonId}
      onClick={onClick}
      disabled={disabled}
      className={className ?? styles.button}
      {...props}
      type="primary"
    >
      {Icon && <Icon />}
      {label}
    </Button>
  );
}
