import React, { useState } from 'react';
import { Modal, Input, Button, message } from 'antd';
import { MailOutlined, WhatsAppOutlined, CopyOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';

interface ShareOptionsProps {
    visible: boolean;
    onClose: () => void;
    shareData: {
        title: string;
        textWhats: string;
        pathUrl: string;
        handleEmailChange: (email: string, encodeUser: string) => void;
    };
}

const ShareOptions = ({ 
  visible, 
  onClose,
  shareData,
 }: ShareOptionsProps) => {
  const userProfile = Cookies.get('profile');
  const [email, setEmail] = useState('');
  const publicUrl = shareData.pathUrl;

const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  
  const sendEmail = () => {
    //Valida se o email é válido
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (!emailRegex.test(email)) {
      message.error('E-mail inválido!');
      return;
    }
    shareData.handleEmailChange(email, userProfile as string);
    onClose();  // Fechar modal após envio
  };

  const openWhatsApp = () => {
    const text = encodeURIComponent(shareData.textWhats);
    const url = encodeURIComponent(publicUrl);
    window.open(`https://wa.me/?text=${text}%20${url}`, "_blank");
    onClose();  // Fechar modal após abrir WhatsApp
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(publicUrl)
      .then(() => {
        message.success('Link copiado para a área de transferência!');
        onClose();
      })
      .catch(err => {
        message.error('Falha ao copiar o link!');
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <Modal
      title={shareData.title}
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <div>
        <Input
          placeholder="Digite o e-mail para enviar"
          prefix={<MailOutlined />}
          value={email}
          onChange={handleEmailChange}
          style={{ marginBottom: '1rem' }}
        />
        <Button
          type="primary"
          block
          onClick={sendEmail}
        >
          Enviar por E-mail
        </Button>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          icon={<WhatsAppOutlined />}
          block
          onClick={openWhatsApp}
          style={{
            backgroundColor: '#25D366',
            borderColor: '#25D366',
            color: 'white'
          }}
        >
          Compartilhar via WhatsApp
        </Button>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          icon={<CopyOutlined />}
          block
          onClick={copyToClipboard}
        >
          Copiar Link
        </Button>
      </div>
    </Modal>
  );
};

export default ShareOptions;
