export const category_tikets = [
    {
        id: 1,
        name: 'Atendimento'
    },
    {
        id: 2,
        name: 'Financeiro'
    },
    {
        id: 3,
        name: 'Manutenção e reparos'
    },
    {
        id: 4,
        name: 'Limpeza e higienização'
    },
    {
        id: 5,
        name: 'Segurança'
    },
    {
        id: 6,
        name: 'Documentação e contratos'
    },
    {
        id: 7,
        name: 'Sugestões e reclamações'
    },
    {
        id: 8,
        name: 'Outros'
    }
]

export const status_tikets = [
    {
        id: 1,
        name: 'Todos'
    },
    {
        id: 2,
        name: 'Aberto'
    },
    {
        id: 3,
        name: 'Em andamento'
    },
    {
        id: 4,
        name: 'Em análise'
    },
    {
        id: 5,
        name: 'Cancelado'
    },
    {
        id: 6,
        name: 'Resolvido'
    }
]

export const colors_status = {
    'Aberto': 'red',
    'Em andamento': 'gold',
    'Em análise': 'volcano',
    'Cancelado': 'magenta',
    'Resolvido': 'green',
}

export const priority_tikets = {
    'baixa': 'blue',
    'media': 'gold',
    'alta': 'red',
}