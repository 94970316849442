import React, { useState } from "react";
import { 
    useDispatch, 
    useSelector 
} from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../../store/store";
import { InputText } from "../../inputText/inputText";
import { ButtonCustom } from "../../customButtom/buttomCustom";
import { setBasicData } from "../../../store/formDataSlice";
import { setCurrentStep } from "../../../store/currentStepSlice";

import styles from "./basicDataStyles.module.scss";
import { clearStringOnlyNumbers, inputMaskCPFCNPJ, inputMaskTELWithDDD } from "../../../libs/masks";
import { validarCNPJ } from "../../../services/validation.service";

export function BasicDataForm() {
    const [errorCNPJ, setErrorCNPJ] = useState({
        error: false,
        message: ""
    });
    const [errorName, setErrorName] = useState({
        error: false,
        message: ""
    });
    const [errorDescription, setErrorDescription] = useState({
        error: false,
        message: ""
    });
    const [errorPhoneNumber, setErrorPhoneNumber] = useState({
        error: false,
        message: ""
    });
    const navigate = useNavigate();
    const dispatch = useDispatch(); 
    const innovationCenter = useSelector((state: RootState) => state.formData.basicData);
    const currentStep = useSelector((state: RootState) => state.currentStep.currentStep);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        const key = name
        if (key === "cnpj") {          
            dispatch(setBasicData({ [name]: clearStringOnlyNumbers(value) }));
            return;
        }
        
        dispatch(setBasicData({ [name]: value }));
    }

    const handleNext = () => {
        
        if (innovationCenter.name === "") { 
            setErrorName({
                error: true,
                message: "Nome do centro de inovação é obrigatório"
            });
            return;
        }
        
        if (!validarCNPJ(innovationCenter.cnpj)) {
            setErrorCNPJ({
                error: true,
                message: "CNPJ inválido"
            });
            return;
        };

        if (innovationCenter.phoneNumber === "") { 
            setErrorPhoneNumber({
                error: true,
                message: "Telefone é obrigatório"
            });
            return;
        }

        setErrorCNPJ({
            error: false,
            message: ""
        });
        setErrorName({
            error: false,
            message: ""
        });
        dispatch(setCurrentStep(currentStep + 1));
    }

    const handleBack = () => {
        navigate('/new-access-control');
    }

    return (
        <>
            <div className={styles.containerBasicData}>
                <InputText 
                    label="Nome do centro de inovação"
                    name="name"
                    type="text"
                    placeholder="Digite o nome do centro de inovação"
                    value={innovationCenter.name}
                    onChange={handleChange}
                    error={errorName.error}
                    errorMensage={errorName.message}
                    required
                />
                <InputText 
                    label="Descrição"
                    name="description"
                    type="text"
                    placeholder="Digite a descrição do centro de inovação"
                    value={innovationCenter.description}
                    onChange={handleChange}
                />
                <InputText 
                    label="CNPJ"
                    name="cnpj"
                    type="text"
                    placeholder="Digite o CNPJ do centro de inovação"
                    value={inputMaskCPFCNPJ(innovationCenter.cnpj)}
                    onChange={handleChange}
                    error={errorCNPJ.error}
                    errorMensage={errorCNPJ.message}
                    maxLength={18}
                    required
                />
                <InputText 
                    label="Telefone"
                    name="phoneNumber"
                    type="text"
                    placeholder="Digite o telefone"
                    onChange={handleChange}
                    value={inputMaskTELWithDDD(innovationCenter.phoneNumber)}
                    error={errorPhoneNumber.error}
                    errorMensage={errorPhoneNumber.message}
                    required
                />
                <InputText 
                    label="Site"
                    name="website"
                    type="text"
                    placeholder="Digite o site"
                    onChange={handleChange}
                    value={innovationCenter.website}
                />
            </div>
            <div className={styles.contentButtons}>
                <ButtonCustom 
                  label="Voltar"
                  onClick={handleBack}
                  style={{backgroundColor: "var(--gray-100)", color: "#000"}}
                />
                <ButtonCustom 
                  label="Próximo" 
                  onClick={handleNext}
                />
            </div>
        </>
    );
}