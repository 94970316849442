import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Descriptions, Empty, Modal, Row, Select } from "antd";
import { ChevronLeft, ChevronRight } from "lucide-react";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import moment from "moment";

import { EventDetails } from "./EventDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { EventsServices } from "../../services/apis/eventsServices";
import { Idate, IEvent } from "../../types/eventTypes";

import styles from "./events.module.scss";

export function PublicEventsPage() {
  const calendarRef = useRef<FullCalendar>(null);
  const [finalEvents, setFinalEvents] = useState<IEvent[]>([]);
  const [event, setEvent] = useState<any>();
  const [eventDetailsVisible, setEventDetailsVisible] = useState(false);
  const [visibleModalFiltered, setVisibleModalFiltered] = useState(false);
  const [currentView, setCurrentView] = useState("dayGridMonth");

  const styleBySubDomain = useSelector(
    (state: RootState) => state.styleSlice.backGroundColorMenu,
    (left, right) => left === right
  );

  const getFormattedDateRange = (dates: Idate[]) => {
    if (dates.length === 0) return "";

    const sortedDates = dates.sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    );
    const firstDate = moment(sortedDates[0].start);
    const lastDate = moment(sortedDates[sortedDates.length - 1].end);

    if (firstDate.isSame(lastDate, "day")) {
      // Se o evento começa e termina no mesmo dia
      return firstDate.format("DD [de] MMMM [de] YYYY");
    } else {
      // Se o evento abrange múltiplos dias
      return `de ${firstDate.format("DD [de] MMMM")} a ${lastDate.format(
        "DD [de] MMMM [de] YYYY"
      )}`;
    }
  };

  const handleViewChange = useCallback((value: string) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.changeView(value);
      setCurrentView(value);
      setVisibleModalFiltered(false);
    }
  }, []);

  const handlePrev = useCallback(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.prev();
      setVisibleModalFiltered(false);
    }
  }, []);

  const handleNext = useCallback(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.next();
      setVisibleModalFiltered(false);
    }
  }, []);

  const handleToday = useCallback(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.today();
      setVisibleModalFiltered(false);
    }
  }, []);

  const handleCustomButtonClick = useCallback(() => {
    setVisibleModalFiltered(true);
  }, []);

  console.log("PublicEvents render");

  const fetchEvents = useCallback(async () => {
    const eventService = new EventsServices();
    try {
      const response = await eventService.getAllPublicEvents();
      const newEvents = response.flatMap((event: IEvent) =>
        event.dates.map((date: Idate) => ({
          title: event.title,
          start: moment(date.start).toDate(),
          end: moment(date.end).toDate(),
          description: event.description,
          movement: event.movement,
          location: event.location,
          accessLink: event.access_link,
          files: event.files,
          user_id: event.user_created_id,
          id_initial: event.id_initial,
          event_id: event.id,
          url_images_events: event.url_images_events,
          dateRange: getFormattedDateRange(event.dates),
        }))
      );
      setFinalEvents(newEvents);
      return;
    } catch (error) {
      console.log(error);
    }
  }, []); // As dependências estão vazias porque `eventService` e outros parecem ser fixos ou fora do escopo deste trecho.

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      {finalEvents.length > 0 && (
        <div
          className={`${
            styleBySubDomain === "var(--purple-800)"
              ? styles.calendarContainerElume
              : styles.calendarContainerBase
          }`}
        >
          <FullCalendar
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              multiMonthPlugin,
            ]}
            headerToolbar={{
              left: window.innerWidth > 768 ? "prev,next today" : "",
              center: "title",
              right:
                window.innerWidth > 768
                  ? "multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay"
                  : "myCustomButton",
            }}
            customButtons={{
              myCustomButton: {
                text: "Filtros",
                click: handleCustomButtonClick,
              },
            }}
            eventClick={(info) => {
              setEvent(info);
              setEventDetailsVisible(true);
            }}
            eventColor={styleBySubDomain}
            locale={ptBrLocale}
            events={finalEvents}
            selectable
            nowIndicator
            expandRows
            slotDuration="01:00:00"
            allDaySlot={false}
            initialView="dayGridMonth"
          />
        </div>
      )}
      {finalEvents.length === 0 && (
        <Row justify="center" style={{ marginTop: "2rem" }}>
          <Col>
            <Empty description="Não há eventos públicos disponíveis." />
          </Col>
        </Row>
      )}
      <Modal
        open={visibleModalFiltered}
        onCancel={() => setVisibleModalFiltered(false)}
        title="Escolha a forma de visualização"
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Descriptions.Item>
          Selecione o intervalo de visualização desejado.
        </Descriptions.Item>
        <Row
          gutter={8}
          style={{
            marginTop: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          <Col>
            <Button
              type="primary"
              icon={<ChevronLeft />}
              onClick={handlePrev}
            ></Button>
          </Col>
          <Col>
            <Button type="primary" onClick={handleToday}>
              Hoje
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<ChevronRight />}
              onClick={handleNext}
            ></Button>
          </Col>
        </Row>
        <Descriptions.Item>Formato de visualização:</Descriptions.Item>
        <Row
          gutter={8}
          style={{
            marginTop: "0.5rem",
            marginBottom: "0.75rem",
          }}
        >
          <Col span={24}>
            <Select
              defaultValue={currentView}
              style={{ width: "100%" }}
              onChange={handleViewChange}
            >
              <Select.Option value="dayGridMonth">Mês</Select.Option>
              <Select.Option value="timeGridWeek">Semana</Select.Option>
              <Select.Option value="timeGridDay">Dia</Select.Option>
              <Select.Option value="multiMonthYear">Ano</Select.Option>
            </Select>
          </Col>
        </Row>
      </Modal>
      <EventDetails
        visible={eventDetailsVisible}
        setVisible={setEventDetailsVisible}
        event={event}
      />
    </>
  );
}
