import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInnovationCenterData {
    cnpj: string;
    name_innovation_center: string;
    description: string;
}

interface Address {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
    complemento: string;
}

interface Contacts {
    email: string;
    phone: string;
    cellphone: string;
    site: string;
}

interface President {
    name: string;
    email: string;
    phone: string;
    biginning_of_term: string;
    end_of_term: string;
}

interface IInnovationCenter {
    innovationCenterData: IInnovationCenterData;
    address: Address;
    contacts: Contacts;
    president: President;
}

const initialState: IInnovationCenter = {
    innovationCenterData: {
        cnpj: '',
        name_innovation_center: '',
        description: '',
    },
    address: {
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: '',
        complemento: '',
    },
    contacts: {
        email: '',
        phone: '',
        cellphone: '',
        site: '',
    },
    president: {
        name: '',
        email: '',
        phone: '',
        biginning_of_term: '',
        end_of_term: '',
    }
};

const updateInnovationCenterSlice = createSlice({
    name: 'updateInnovationCenterForm',
    initialState,
    reducers: {
        setInnovationCenterData: (state, action: PayloadAction<Partial<IInnovationCenter['innovationCenterData']>>) => {
            state.innovationCenterData = {...state.innovationCenterData, ...action.payload};
        },
        setAddress: (state, action: PayloadAction<Partial<IInnovationCenter['address']>>) => {
            state.address = {...state.address, ...action.payload};
        },
        setContacts: (state, action: PayloadAction<Partial<IInnovationCenter['contacts']>>) => {
            state.contacts = {...state.contacts, ...action.payload};
        },
        setPresident: (state, action: PayloadAction<Partial<IInnovationCenter['president']>>) => {
            state.president = {...state.president, ...action.payload};
        },
        resetFormInnovationCenter: () => initialState,
    },
});

export const {
    resetFormInnovationCenter,
    setInnovationCenterData,
    setAddress,
    setContacts,
    setPresident,
} = updateInnovationCenterSlice.actions;

export default updateInnovationCenterSlice.reducer;