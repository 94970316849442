import { ProfileServices } from "./apis/profileServices";

class PropertiesProfileService {
  async readNameById(id: string) {
    const profileService = new ProfileServices();
    try {
      const response = await profileService.getProfileById(id);
      return response.data[0].name_user;
    } catch (error: any) {
      return error;
    }
  }
}

export default new PropertiesProfileService();