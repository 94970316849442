import { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Divider,
  Form,
  Input,
  Modal,
  Result,
  Select,
  Spin,
  Switch,
  notification,
} from "antd";
import { HeaderFilters } from "../../../components/header_filters/headerFilters";
import { UserService } from "../../../services/apis/userService";
import { UserCard } from "./cardUser";
import { NotificationType } from "../../../types/commonTypes";
import {
  clearStringOnlyNumbers,
  inputMaskCPFCNPJ,
  inputMaskTELWithDDD,
} from "../../../libs/masks";
import Cookies from "js-cookie";

import styles from "./users.module.scss";
import { ListContainer } from "../../../components/list/listContainer";

const { Option } = Select;

interface Startup {
  id: number;
  created_at: string;
  cnpj: string;
  cei: string;
  nome_fantasia: string;
  razao_social: string;
  categoria: string;
  data_fundacao: string;
  numero_funcionarios: string;
  cnae: string;
  contabilidade: string;
  valor_pagamento: string;
  contatos: {
    email: string;
    telefone: string;
    celular: string;
    site: string;
  };
  endereco: {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
    complemento: string;
  };
  owners: {
    nome_responsavel: string;
    cargo: string;
  };
  porte: string;
  instituition_owner: string;
  instituition_cnpj_owner: string;
  id_initial_create_enterprise: string;
}

interface User {
  id: string;
  id_auth: string;
  created_at: string;
  cpf: string;
  name: string;
  email: string;
  phone_number: string;
  instituition_id_owner: string;
  instituition_owner: string;
  status: string;
  role: string;
  role_ref: string;
  permission_events: boolean;
}

interface DataType extends User {
  gender?: string;
  picture: {
    large?: string;
    medium?: string;
    thumbnail?: string;
  };
  nat?: string;
  loading: boolean;
}

interface Filter {
  text: string;
  cascade: [keyof DataType, any][];
}

export function Users() {
  const userServices = new UserService();
  const currentWidth = window.innerWidth;
  const currentProfile = Cookies.get("profile");
  const parseProfile = currentProfile ? JSON.parse(currentProfile) : null;
  const user = Cookies.get("user");
  const parseUser = user ? JSON.parse(user) : null;
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [typeModal, setTypeModal] = useState({
    type: "",
    title: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [list, setList] = useState<DataType[]>([]);
  const [count, setCount] = useState(10);
  const [filteredData, setFilteredData] = useState<DataType[]>([]);
  const [filters, setFilters] = useState<Filter>({ text: "", cascade: [] });
  const [initLoading, setInitLoading] = useState(true);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState({
    open: false,
    name: "",
    id: "",
  });
  const [errorRequest, setErrorRequest] = useState({
    status: false,
    message: "",
  });

  const descriptionRoles = {
    ADM: "Administrativo",
    ATENDIMENTO: "Chamados",
    EVENTOS: "Eventos",
  };

  const userCascadeFilters = [
    {
      value: "status",
      label: "Status",
      children: [
        {
          value: "active",
          label: "Ativo",
        },
        {
          value: "inactive",
          label: "Inativo",
        },
      ],
    },
    {
      value: "role",
      label: "Nível de Acesso",
      children: [
        {
          value: "ADM",
          label: "Administrativo",
        },
        {
          value: "ATENDIMENTO",
          label: "Chamados",
        },
        {
          value: "EVENTOS",
          label: "Eventos",
        },
      ],
    },
    {
      value: "permission_events",
      label: "Permissão de Eventos",
      children: [
        {
          value: 'sim',
          label: "Sim",
        },
        {
          value: 'não',
          label: "Não",
        },
      ],
    }
  ];

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const onLoadMore = () => {
    setCount(count + 5);
  };

  function removeAccents(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const applyFilters = (data: DataType[], { text, cascade }: Filter) => {
    // Convertendo o array de arrays para um objeto onde as chaves são os campos e os valores são arrays dos valores selecionados
    const cascadeObject = cascade.reduce((acc, [key, value]) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(value);
      return acc;
    }, {} as Record<string, any[]>);

    return data.filter((item) => {
      const textMatch =
        removeAccents(item.name.toLowerCase()).includes(
          text.toLowerCase()
        ) ||
        removeAccents(item.instituition_owner.toLowerCase()).includes(
          text.toLowerCase()
        );
      const cascadeMatch = Object.entries(cascadeObject).every(
        ([key, values]) => {
          if (key in item) {
            if (!values[0]) return true; // Se o array de valores está vazio, consideramos um match
            // Se key === permission_events verificar values se for 'sim' transforma em true, se for 'não' transforma em false
            if (key === 'permission_events') {
              const value = values[0] === 'sim' ? true : false;
              return item[key as keyof DataType] === value;
            }
            // Checa se o valor do item está incluído nos valores selecionados para esse filtro
            return values.includes(item[key as keyof DataType]);
          }
          return false; // Se a chave não existe, não consideramos um match
        }
      );
      return textMatch && cascadeMatch;
    });
  };

  const handleTextFilterChange = (value: string) => {
    setFilters((prev) => ({ ...prev, text: value }));
  };

  const handleCascadeFilterChange = (value: [keyof DataType, any][]) => {
    setFilters((prev) => ({ ...prev, cascade: value }));
  };

  const fetchData = async () => {
    setInitLoading(true);
    try {
      if (parseProfile[0].role === "ADM") {
        const resp = await userServices.getUserByCnpjOwner(
          parseProfile[0].institution_cnpj_owner
        );
        const usersValid = resp.users.filter(
          (user: User) => user.id_auth !== parseUser.id
        );
        setList(usersValid);
        setFilteredData(applyFilters(usersValid, filters));
        setInitLoading(false);
        return;
      } else {
        const resp = await userServices.getUsers();
        setList(resp.users);
        setFilteredData(applyFilters(resp.users, filters));
        setInitLoading(false);
      }
    } catch (error) {
      setInitLoading(false);
      return;
    }
  };

  const updateStatus = async (id: string, status: string) => {
    setLoadingSwitch(true);
    try {
      await userServices.upadateUser(id, { status });
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Status do usuário atualizado com sucesso"
      );
      fetchData();
      setLoadingSwitch(false);
    } catch (error: any) {
      openNotificationWithIcon("error", "Erro", error.message);
      setLoadingSwitch(false);
    };
  }

  const requestPasswordReset = async (id: string) => {
    setLoadingResetPassword(true);
    try {
      await userServices.resetPassword(id);
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Solicitação de redefinição de senha enviada com sucesso"
      );
      setLoadingResetPassword(false);
    } catch (error: any) {
      openNotificationWithIcon("error", "Erro", error.message);
      setLoadingResetPassword(false);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      if (parseProfile[0].role === "ADM") {
        await userServices.createUserByAdmin({
          name: values.name,
          email: values.email,
          phone_number: clearStringOnlyNumbers(values.phone_number),
          cpf: clearStringOnlyNumbers(values.cpf),
          role: values.role,
          status: values.status,
          role_ref: parseProfile[0].role_ref,
          cnpjOwnerRef: parseProfile[0].institution_cnpj_owner,
          nameOwnerRef: parseProfile[0].institution_owner,
        });
      } else {
        await userServices.createUser({
          name: values.name,
          email: values.email,
          phone_number: clearStringOnlyNumbers(values.phone_number),
          cpf: clearStringOnlyNumbers(values.cpf),
          role: values.role,
          status: values.status,
          role_ref: parseProfile[0].role,
        });
      }
      setVisible(false);
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Usuário criado com sucesso"
      );
      setLoading(false);
      form.resetFields();
    } catch (error: any) {
      setLoading(false);
      openNotificationWithIcon("error", "Erro", error.message);
    }
  };

  const onFinishEdit = async (values: any) => {
    setLoading(true);
    try {
      await userServices.upadateUser(currentUser?.id ?? '', {
        name: values.name,
        phone_number: clearStringOnlyNumbers(values.phone_number),
        role: values.role,
        status: values.status,
        permission_events: values.permissionEvents,
      });
      setVisible(false);
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Usuário atualizado com sucesso"
      );
      setLoading(false);
      fetchData();
      form.resetFields();
    } catch (error: any) {
      setLoading(false);
      openNotificationWithIcon("error", "Erro", error.message);
    }
  }

  const cancelDelete = () => {
    setErrorRequest({
      status: false,
      message: "",
    });
    setOpenModalDelete({
      open: false,
      name: "",
      id: "",
    });
  };

  const confirmDelete = async (id_auth: string) => {
    setLoading(true);
    try {
      await userServices.deleteUser(id_auth);
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Usuário deletado com sucesso"
      );
      fetchData();
      setLoading(false);
      setOpenModalDelete({
        open: false,
        name: "",
        id: "",
      });
    } catch (error: any) {
      setErrorRequest({
        status: true,
        message: error.message,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser && typeModal.type === "edit") {
      form.setFieldsValue({
        name: currentUser.name,
        email: currentUser.email,
        phone_number: currentUser.phone_number,
        cpf: currentUser.cpf,
        role: currentUser.role,
        status: currentUser.status,
        permissionEvents: currentUser.permission_events,
      });
    } else {
      form.resetFields();
    }
  }, [currentUser, typeModal]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setFilteredData(applyFilters(list, filters));
  }, [list, filters]);

  return (
    <div>
      {contextHolder}
      <HeaderFilters
        text="Usuário"
        changeButton={() => {
          setVisible(true);
          setTypeModal({
            type: "create",
            title: "Criar Usuário",
            description: "",
          });
        }}
        optionsCascader={userCascadeFilters}
        changeCascader={handleCascadeFilterChange}
        changeSearch={handleTextFilterChange}
        disabledCascade={false}
        disabledSearch={false}
      />
      <ListContainer
        setCurrentUser={setCurrentUser}
        setTypeModal={setTypeModal}
        setVisible={setVisible}
        dataUsers={filteredData}
        initLoading={initLoading}
        count={count}
        onLoadMore={onLoadMore}
        updateStatus={updateStatus}
        requestPasswordReset={requestPasswordReset}
        setLoadingSwitch={setLoadingSwitch}
        setLoadingResetPassword={setLoadingResetPassword}
        loadingSwitch={loadingSwitch}
        loadingResetPassword={loadingResetPassword}
        setOpenModalDelete={setOpenModalDelete}
        openModalDelete={openModalDelete}
      />
      <Modal
        title={typeModal.title}
        open={visible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setVisible(false);
        }}
        width={currentWidth < 768 ? "100%" : "50%"}
      >
        <Form
          className={styles.formContainer}
          onFinish={(typeModal.type === "create") ? onFinish : onFinishEdit}
          form={form}
          layout="vertical"
        >
          <Form.Item
            className={styles.formItem}
            label="Nome"
            name="name"
            rules={[{ required: true, message: "Campo nome é obrigatório" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Campo E-mail é obrigatório" },
              { type: "email", message: "E-mail invalido" },
            ]}
          >
            <Input 
              disabled={typeModal.type === "edit"}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            label="Telefone"
            name="phone_number"
            rules={[
              { required: true, message: "Campo telefone é obrigatório" },
            ]}
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({
                  phone_number: inputMaskTELWithDDD(e.target.value),
                });
              }}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            label="CPF"
            name="cpf"
            rules={[{ 
              required: true, 
              message: "Campo CPF é obrigatório" 
            }]}
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({ cpf: inputMaskCPFCNPJ(e.target.value) });
              }}
              disabled={typeModal.type === "edit"}
            />
          </Form.Item>

          <Form.Item
            name="role"
            label="Nivel de acesso"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(29% - 8px)",
            }}
          >
            <Select placeholder="Selecione">
              <Option value="ADM">Administrativo</Option>
              <Option value="ATENDIMENTO">Chamado</Option>
            </Select>
          </Form.Item>

          {((typeModal.type === 'edit') && 
          (currentUser?.role !== 'EVENTOS')) && 
          parseProfile[0]?.events_register_permission &&
          <Form.Item
            name="permissionEvents"
            label="Permissão de Eventos"
            style={{
              display: "inline-block",
              width: "calc(29% - 8px)",
            }}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>}

          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "Campo status é obrigatório" }]}
            style={{
              display: "inline-block",
              width: "calc(29% - 8px)",
            }}
          >
            <Select placeholder="Selecione">
              <Option value="active">Ativo</Option>
              <Option value="inactive">Inativo</Option>
            </Select>
          </Form.Item>
          <div className={styles.formButtonContainer}>
            <Form.Item className={styles.formButton}>
              <button
                type="button"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Voltar
              </button>
            </Form.Item>
            <Form.Item className={styles.formButton}>
              <Button type="primary" htmlType="submit">
                {typeModal.type === "create" ? "Cadastrar" : "Editar"}
              </Button>
            </Form.Item>
          </div>
        </Form>
        <Spin spinning={loading} fullscreen />
      </Modal>
      <Modal
        title={`Deletar ${openModalDelete.name}?`}
        open={openModalDelete.open}
        onOk={() => confirmDelete(openModalDelete.id)}
        onCancel={cancelDelete}
        okText="Sim"
        cancelText="Cancelar"
      >
        {!loading && !errorRequest.status && (
          <Result
            status="error"
            title="Deseja realmente deletar esse usuário?"
            subTitle="Essa ação não poderá ser desfeita."
          />
        )}
        <Spin
          tip="Loading"
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          spinning={loading}
        >
          <div />
        </Spin>
        {errorRequest.status && !loading && (
          <Result
            status="warning"
            title="Erro ao deletar startup"
            subTitle={errorRequest.message}
          />
        )}
      </Modal>
    </div>
  );
}
