import React from "react";
import { Alert, AutoComplete, AutoCompleteProps, Popconfirm, Spin } from "antd";
import { Info } from "lucide-react";

import styles from './inputTextFilterStyle.module.scss';

interface InputTextProps {
  label?: string;
  name?: string;
  type?: string;
  errorMensage?: string;
  placeholder?: string;
  value?: any;
  onChange?: (value: string) => void;
  selected?: (value: string) => void;
  error?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  id?: string;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  title?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  inputRef?: React.RefObject<HTMLInputElement> | any;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  style?: React.CSSProperties;
  styleLabel?: React.CSSProperties;
  options: {
    value: string;
    sigla?: string;
  }[];
  loading?: boolean;
}

export function InputTextFilter({
  label,
  name,
  type,
  placeholder,
  value,
  onChange,
  error = false,
  errorMensage,
  onBlur,
  disabled,
  required,
  className,
  id,
  maxLength,
  minLength,
  pattern,
  title,
  autoComplete,
  autoFocus,
  inputRef,
  inputProps,
  labelProps,
  options,
  selected,
  loading,
  style,
  styleLabel,
  ...props
}: InputTextProps): JSX.Element {
  const inputId = id || name;
  const inputPlaceholder = placeholder ? placeholder : '';
  const inputLabel = label ? label : '';
  const inputValue = value ? value : '';
  const inputOnChange = onChange ? onChange : () => {};
  const inputInputRef = inputRef ? inputRef : null;

  return (
    <div 
      className={styles.container}
      >
        <label 
          htmlFor={inputId} 
          className={styles.labelInput} 
          style={error ? {color: 'var(--red-500)'} : styleLabel ? styleLabel : {}}
          {...labelProps}
        >
            {inputLabel}{required && ' *'}
        </label>
        {error && 
        <Alert
          message="Erro"
          description={errorMensage || 'Campo obrigatório'}
          type="error"
          showIcon
        />}
        <Popconfirm
          open={error}
          title="Erro"
          description={errorMensage || 'Campo obrigatório'}
          icon={<Info color="red" size={20} style={{marginRight: 5}}/>}
          okButtonProps={{style: {display: 'none'}}}
          cancelButtonProps={{style: {display: 'none'}}}         
        >
        <div 
          className={styles.contentInput}
          style={error ? {border: '2px solid var(--red-500)'} : style ? style : {}}
        >
        <AutoComplete
            style={{ 
              width: '100%',
              height: '100%',
              margin: '0',
              border: 'none',
              borderRadius: '0',
              outline: 'none',
            }}
            value={inputValue}
            onChange={inputOnChange}
            onSelect={selected}
            options={options}
            ref={inputInputRef}
            placeholder={inputPlaceholder}
            filterOption={(inputValue, option) =>
              option!.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().indexOf(inputValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) !== -1
            }
        />
        {loading && <Spin />}
        </div>
        </Popconfirm>
    </div>
  )
}