import { api } from './configs';

export class AddressService {
    getAddressByCep = async (cep: string) => {
        try {
            const response = await api.post('/address/by-cep', {
                cep,
            });
            return response.data;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    getUfs = async () => {
        try {
            const response = await api.get('/address/ufs');
            return response.data;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    getCitiesByUf = async (uf: number) => {
        try {
            const response = await api.post(`/address/cities/`, {
                uf
            });
            return response.data;
        } catch (error: any) {
            throw new Error(error);
        }
    }
}