import Cookies from "js-cookie";
import axios from "axios";
import { IChangePassword, ISignIn } from "../../types/authTypes";
import { api } from "./configs";

export class AccessControl {
    signIn = async (data: ISignIn) => {
        try {
            const response = await api.post('/auth/login', data);   
            const user = JSON.stringify(response.data.data.user);
            const profile = JSON.stringify(response.data.data.profile);
            const accessToken = response.data.data.accessToken;

            Cookies.set('access_token', accessToken, { expires: 1, sameSite: 'strict'});

            Cookies.set('user', user, { expires: 1, sameSite: 'strict'});

            Cookies.set('profile', profile, { expires: 1, sameSite: 'strict'});
            
            if (!response.data.data.profile[0].verified_email) {
                throw new Error('Email não verificado');
            }

            return response.data;
        } catch (error: any) {
            console.log('Error', error);
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    validateToken = async (jwt: string) => {
        try {
            const response = await api.post('/auth/retrieve-user-jwt', {
                jwt
            });
            return response.data;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    signOut = () => {
        Cookies.remove('access_token');
        Cookies.remove('user');
        Cookies.remove('profile');
    }

    changePassword = async (data: IChangePassword) => {
        try {
            const response = await api.post('/auth/recover-password', data);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    resetPassword = async (data: IChangePassword) => {
        try {
            const response = await api.post('/auth/reset-password', data);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    //reset password criado por necessidade de liberação de acesso controlado
    resetPasswordByAdmin = async (data: IChangePassword) => {
        try {
            const response = await api.post('/auth/reset-password-admim', data);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }
}