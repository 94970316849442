import React from "react";
import { Steps } from "antd";
import styles from "./stepStyles.module.scss";

interface IStep {
    title: string;
    description: string;
}

interface StepViewProps {
    step: IStep[];
    currentStep: number;
}


export const StepView: React.FC<StepViewProps> = ({ step, currentStep }) => {
  return (
    <div className={styles.containerStep}>
      <Steps current={currentStep} labelPlacement="vertical" items={step} />
    </div>
  );
}