import { Button, Result, Skeleton } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { resetFormData } from "../../store/formDataSlice";
import { setCurrentStep } from "../../store/currentStepSlice";
import { resetScrennConfirmation } from "../../store/screenConfirmationSlice";
import { resetFormStartup } from "../../store/formCreateStartUpSlice";

interface ScreensConfirmationProps {
    status: string;
    feature?: string;
    subTitle?: string;
    navigateTo?: string;
    buttonError?: string;
    buttonSuccess?: string;
}

export function ScreensConfirmation({
    status, 
    feature, 
    subTitle, 
    navigateTo, 
    buttonError, 
    buttonSuccess
}: ScreensConfirmationProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const clearStates = () => {
        dispatch(resetFormData());
        dispatch(setCurrentStep(0));
        dispatch(resetScrennConfirmation());
        dispatch(resetFormStartup());
        navigate(navigateTo ? navigateTo : '/');
    }

    if (status === 'pending') {
        return (
            <Skeleton 
                active
            />
        );
    } else if (status === 'approved') {
        return (
            <Result
              status="success"
              title={feature ? `${feature} realizado com sucesso!` : "Solicitação realizada com sucesso!"}
              subTitle={subTitle ? subTitle : ""}
              extra={[
                <Button 
                    type="primary" 
                    key="console"
                    onClick={clearStates}
                >
                    {buttonSuccess ? buttonSuccess : 'Acessar o sistema'}
                </Button>,
              ]}
            />
        );
    } else if (status === 'rejected') {
        return (
            <Result
               status="error"
               title={`Erro durante ${feature ? feature : "solicitação"}!`}
               subTitle={subTitle ? subTitle : "Erro inesperado, entre em contato com o suporte"}
               extra={[
                 <Button 
                    type="primary" 
                    key="console"
                    onClick={clearStates}
                >
                   {buttonError ? buttonError : 'Ir para o login'}
                 </Button>,
               ]}
            />
        );
    } else if (status === 'developing') {
        return (
            <Result
               status="404"
               title="Funcionalidade em desenvolvimento"
               subTitle={subTitle ? subTitle : "Essa funcionalidade ainda está em desenvolvimento, tente novamente mais tarde"}
               //extra={[
               //  <Button 
               //     type="primary" 
               //     key="console"
               //     onClick={clearStates}
               // >
               //    Ir para o login
               //  </Button>,
               //]}
            />
        );
    }

    return <Skeleton active/>
}