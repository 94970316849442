import axios from "axios";
import { api } from "./configs";

export class InnovationCenterService {
    readInnovationCenterByCnpj = async (cnpj: string) => {
        try {
            const response = await api.get(`/innovation-center/info/${cnpj}`);
            return response.data[0];
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    upDateInnovationCenter = async (cnpj: string, data: any) => {
        try {
            const response = await api.put(`/innovation-center/update/${cnpj}`, {
                ...data
            });
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }
}