import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Spin, notification } from 'antd';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { CircleX } from 'lucide-react';

import Cookies from 'js-cookie';

import { InputText } from '../../components/inputText/inputText';
import { ButtonCustom } from '../../components/customButtom/buttomCustom';
import { NotificationType } from '../../types/commonTypes';
import { AccessControl as AccessControlClass } from '../../services/apis/accessControlServices';

import LogoImg from '../../assets/images/Intellecthub-logo3.png';
import BrainIntellectImg from '../../assets/images/logo-brain.jpeg';

import styles from './accessPage.module.scss';

export function AccessControl(): JSX.Element {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const refbutton = useRef<HTMLButtonElement>(null);
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [errorUser, setErrorUser] = useState({
    error: false,
    message: ''
  });
  const [errorPassword, setErrorPassword] = useState({
    error: false,
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [showHelpDesk, setShowHelpDesk] = useState(false);

  useEffect(() => {
    const email = Cookies.get('remenberEmail');
    if (email) {
      setUser(email);
      setRemember(true);
    }
  }, []);


  const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const handleSignIn = async () => {
    setLoading(true);
    try {
      const authServices = new AccessControlClass();

      if (!user) {
        setLoading(false);
        setErrorUser({
          error: true,
          message: 'Usuário não informado'
        });
        return;
      }

      if (!password) {
        setLoading(false);
        setErrorPassword({
          error: true,
          message: 'Senha não informada'
        });
        return;
      }

      if (remember) {
        Cookies.set('remenberEmail', user, { expires: 182, sameSite: 'strict' })
      }

      setErrorUser({
        error: false,
        message: ''
      });
      setErrorPassword({
        error: false,
        message: ''
      });

      const respLogin = await authServices.signIn({
        email: user,
        password
      });
      
      navigate('/');
      setLoading(false);
    } catch (error: any) {
      openNotificationWithIcon('error', 'Erro ao efetuar login!', error.message);
      setLoading(false);
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSignIn();
      refbutton.current?.focus();
    }
  }

  const handleClicked = () => {
    setShowHelpDesk(true);
  }

  const handleClosedHelpDesk = () => {
    setShowHelpDesk(false);
  }

  function TitleHelpDesk() {
    return (
      <div className={styles.titleHelpDesk}>
        <div>
          <p>Precisando de ajuda?</p>
          <CircleX 
            onClick={handleClosedHelpDesk}
          />
        </div>
        <p>Abra um chamdo com a sua solicitação!</p>
      </div>
    );
  }

  return (
    <div className={styles.containerLogin}>
      {contextHolder}
      <div className={styles.boxForm}>
        <img src={LogoImg} alt="Logo IntellectHub" />
        <InputText 
          label="Usuário"
          name="user"
          type="text"
          placeholder="Digite seu usuário"
          value={user}
          onChange={(e) => setUser(e.target.value)}
          error={errorUser.error}
          errorMensage={errorUser.message}
        />
        <InputText 
          label="Senha"
          name="password"
          type="password"
          placeholder="Digite sua senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
          error={errorPassword.error}
          errorMensage={errorPassword.message}
        />
        <div className={styles.boxRemember}>
          <div className={styles.contentRemenber}>
            <input 
              type="checkbox" 
              id="remember" 
              name="remember" 
              checked={remember}
              onChange={() => setRemember(!remember)}
            />
            <label htmlFor="remember">Lembrar de mim</label>
          </div>
          <div className={styles.boxForgotPass}>
            <a href="#" className={styles.forgotPassword}>Esqueci minha senha</a>
          </div>
        </div>
        <ButtonCustom 
          label="Entrar"
          onClick={handleSignIn}
          ref={refbutton}
        />
        <div className={styles.divider}>
          <hr className={styles.dividerLine} />
          <span className={styles.dividerText}>ou</span>
          <hr className={styles.dividerLine} />
        </div>
        <ButtonCustom 
          label="Cadastrar"
          onClick={() => navigate('/registration/innovation-center')}
        />
        <Spin 
          tip="Loading" 
          size="large"
          className={styles.spinLoading}
          spinning={loading}
        >
          <div className={styles.contentSpin}/>
        </Spin>
      </div>
      <div className={styles.boxButtonFlutuante}>
        {showHelpDesk &&
          <Card 
            title={<TitleHelpDesk />} 
            content='Nosso time está pronto para te ajudar!'
            className={styles.cardInfo}
            max-width='300px'
          >
            <InputText 
              label="E-mail"
              name="email"
              type="text"
              placeholder="Digite o e-mail da sua empresa"
              styleLabel={{
                color: 'var(--blue-900)'
              }}
              style={{
                border: '1px solid var(--blue-900)',
              }}
            />
            <InputText 
              label="CNPJ"
              name="cnpj"
              type="text"
              placeholder="Digite o CNPJ da sua empresa"
              styleLabel={{
                color: 'var(--blue-900)'
              }}
              style={{
                border: '1px solid var(--blue-900)',
              }}
            />
            <ButtonCustom 
              label="Abrir chamado"
              onClick={() => {}}
            />
          </Card>
        }
        <FloatingWhatsApp 
          phoneNumber='5567991721011'
          accountName='IntellectHub'
          statusMessage='Nosso time está pronto para te ajudar!'
          chatMessage='Bem-vindo ao IntellectHub! Como podemos te ajudar?'
          allowClickAway={true}
          allowEsc={true}
          onClick={handleClicked}
          avatar={BrainIntellectImg}
          chatboxStyle={{
            maxWidth: '350px',
            display: 'none',
          }}
        />
      </div>
    </div>
  );
}