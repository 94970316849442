import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CurrentStepState {
    currentStep: number;
}

const initialState: CurrentStepState = {
    currentStep: 0,
};

const CurrentStepSlice = createSlice({
  name: 'currentStep',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      { state.currentStep = action.payload };
    },
  },
});

export const { setCurrentStep } = CurrentStepSlice.actions;
export default CurrentStepSlice.reducer;