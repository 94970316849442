import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BasicDataState {
  basicData: {
    name: string;
    description: string;
    cnpj: string;
    phoneNumber: string;
    website: string;
  };
  addressData: {
    street: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
  };
  presidentData: {
    name_president: string;
    email_president: string;
    phone_president: string;
    start_date_term: string;
    end_of_term: string;
    signature: string;
  };
  credentialData: {
    email_credential: string;
    password_credential: string;
    password_confirmation_credential: string;
  };
}

const initialState: BasicDataState = {
  basicData: {
    name: '',
    description: '',
    cnpj: '',
    phoneNumber: '',
    website: '',
  },
  addressData: {
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
  },
  presidentData: {
    name_president: '',
    email_president: '',
    phone_president: '',
    start_date_term: '',
    end_of_term: '',
    signature: '',
  },
  credentialData: {
    email_credential: '',
    password_credential: '',
    password_confirmation_credential: '',
  },
};

const formDataSlice = createSlice({
  name: 'basicData',
  initialState,
  reducers: {
    setBasicData: (state, action: PayloadAction<Partial<BasicDataState['basicData']>>) => {
      state.basicData = { ...state.basicData, ...action.payload };
    },
    setAddressData: (state, action: PayloadAction<Partial<BasicDataState['addressData']>>) => {
      state.addressData = { ...state.addressData, ...action.payload };
    },
    setPresidentData: (state, action: PayloadAction<Partial<BasicDataState['presidentData']>>) => {
      state.presidentData = { ...state.presidentData, ...action.payload };
    },
    setCredentialData: (state, action: PayloadAction<Partial<BasicDataState['credentialData']>>) => {
      state.credentialData = { ...state.credentialData, ...action.payload };
    },
    resetFormData: () => initialState,
  },
});

export const { setBasicData, setAddressData, setPresidentData, setCredentialData, resetFormData } = formDataSlice.actions;
export default formDataSlice.reducer;