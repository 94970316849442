let socket: WebSocket | null = null;

export const connectWebSocket = (
  url: string,
  dispatch: (action: any) => void,
  handleMessage: (message: any, dispatch: (action: any) => void) => void
): void => {
  socket = new WebSocket(url);

  socket.onopen = () => {
    console.log('Connected to WebSocket server');
  };

  socket.onmessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    handleMessage(message, dispatch);
  };

  socket.onclose = () => {
    console.log('Disconnected from WebSocket server');
  };
};

export const closeWebSocket = (): void => {
  if (socket) {
    socket.close();
  }
};
